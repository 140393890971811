import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMovieList, getAlltheData } from '../../actions/movielist-actions'
import { appHeightChanged } from '../../actions/app-actions'
import './styles/program.scss'





class Biografer extends React.Component {
   
    constructor() {
        super();
        this.toFindDuplicates = this.toFindDuplicates.bind(this)

    }
    componentDidMount() {
        const { actions, movies, organizerIds, allOrganizers } = this.props
        if (movies.length == 0) {
            actions.getMovieList(3);
            actions.getAlltheData()
        }
       
    }


   // allOrganizers.filter((i) => arr.push(i.region))
   //// console.log(arr)
    toFindDuplicates() {
        let arr = []
        const { allOrganizers } = this.props
       allOrganizers && allOrganizers.filter((i) => arr.push(i.region))
        const uniqueElements = new Set(arr);
        // const filteredElements =
        arr.filter((item) => {
            if (uniqueElements.has(item)) {
                // uniqueElements.delete(item);
            } else {
                return item;
            }
        });

        return [...new Set(uniqueElements)];
    }

   // ;
   // console.log(duplicateElements)
    render() {
        const { allOrganizers } = this.props
        const duplicateElements = this.toFindDuplicates()
        
        return (
            <div className='biograferContainer' style={{ marginTop: allOrganizers ? '-30px' : '0px' }}> 
                {allOrganizers ? 
                    <>
                <p className='bio_title' >Biografer</p>
                <p className='bio_count'>I alt: {allOrganizers && allOrganizers.length} biografer bruger ebillet</p>

                
                <section className='biografer'>
               
                <div style={{ display:'flex', flexDirection:'column' }}>
                    {duplicateElements.sort((a, b) => a > b ? 1 : -1).map((i,index) => {
                        if (i === 'Bornholm' || i === 'Færøerne' || i === 'Fyn' || i === 'Grønland')
                            return <div key={index }>
                                <p style={{ color: '#ecc590', fontSize: '22px' }}>{i}</p>
                                {allOrganizers.map((org,indx) => {
                                    if (org.region === i) {
                                        return <div key={ indx} style={{}}>
                                            <span style={{ height: '10px', fontSize: '14px', color: 'white' }}>{org.city} ,</span>
                                            <span style={{ color: 'lightgrey', fontSize: '14px' }}> {org.name} </span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                                                <path d="M13 6.5L7.75 9.53109L7.75 3.46891L13 6.5Z" fill="white" />
                                            </svg>
                                        </div>
                                    }

                                })}
                            </div>
                    } )}
                </div>

                <div style={{ color: 'lightgrey', fontSize: '14px' }}>
                    {duplicateElements.sort((a, b) => a > b ? 1 : -1).map((i,index) => {
                        if (i === 'Samsø' || i === 'Lolland Falster' || i === 'Jylland' )
                            return <div key={index }>
                                <p style={{ color: '#ecc590', fontSize: '22px' }}>{i}</p>
                                {allOrganizers.map((org,indx) => {
                                    if (org.region === i) {
                                        return <div key={ indx } style={{}}>
                                            <span style={{ height: '10px', fontSize: '14px', color: 'white' }}>{org.city} ,</span>
                                            <span style={{ color: 'lightgrey', fontSize: '14px' }}> {org.name} </span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                                                <path d="M13 6.5L7.75 9.53109L7.75 3.46891L13 6.5Z" fill="white" />
                                            </svg>
                                        </div>
                                    }

                                })}
                            </div>
                    })}
                </div>
                <div style={{ color: 'lightgrey', fontSize: '14px' }}>
                {duplicateElements.sort((a, b) => a > b ? 1 : -1).map((i,index) => {
                    if (i === 'Sjælland' || i === 'Storkøbenhavn')
                        return <div key={index }>
                            <p style={{ color: '#ecc590', fontSize: '22px' }}>{i}</p>
                            {allOrganizers.map((org,indx) => {
                                if (org.region === i) {
                                    return <div key={indx} style={{}}>
                                        <span style={{ height: '10px', fontSize: '14px', color: 'white' }}>{org.city} ,</span>
                                        <span style={{ color: 'lightgrey', fontSize: '14px' }}> {org.name} </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
                                            <path d="M13 6.5L7.75 9.53109L7.75 3.46891L13 6.5Z" fill="white" />
                                        </svg>
                                    </div>
                                }

                            })}
                        </div>
                })}
                </div>
        
                    
                        </section>
                    </>

           : <div className='spinner'></div>}
           
        </div>
    )
    }
}

const mapStateToProps = (state, ownProps) => {
    
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const movies = listConfig.date.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        movies,
        allMovies: state.movielist.allMovies,
        allEvents: state.movielist.allEvents,
        allLocations: state.movielist.allLocations,
        allMovieBases: state.movielist.allMovieBases,
        allOrganizers: state.movielist.allOrganizers
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getAlltheData, appHeightChanged, getMovieList }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Biografer)