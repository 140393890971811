import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        paymentMethod: 'Vælg betalingsmetode',
        giftCard: 'Indløs gavekort  ❯',
        payWithReepay: 'Fortsæt til betaling  ❯',
        creditCardForeign: 'Udenlandsk',
        back: '❮  Tilbage',
        totalPrice: 'Pris i alt',
        errorCreditCard: 'Du skal vælge et kreditkort for at fortsætte',
        errorBack: 'Du kan ikke gå tilbage når du har indløst gavekort',
        currency: 'kr.',
        pay: 'Gennemfør betaling',
        processing:'Behandling...',
        payZero: 'Gennemfør Køb',
        payMethod:'Betalingsmetode'
    },

    en: {
        ...OrderFlowLocale.en,
        paymentMethod: 'Choose payment method',
        giftCard: 'Use gift card  ❯',
        payWithReepay: 'Continue to payment  ❯',
        creditCardForeign: 'Foreign',
        errorCreditCard: 'You have to choose a credit card to continue',
        errorBack: 'You can not go back when you have added a gift card voucher',
        totalPrice: 'Total',
        currency: 'DKK',
        pay: 'Complete Payment',
        processing: 'Processing...',
        payZero: 'Complete checkout',
        payMethod: 'Paying method'
    },

    fo: {
        ...OrderFlowLocale.fo,
        paymentMethod: 'Vel gjaldshátt',
        giftCard: 'Innloys gávukort  ❯',
        payWithReepay: 'Far til gjalding  ❯',
        creditCardForeign: 'Útlendsk',
        errorCreditCard: 'Vel gjaldskort fyri at halda áfram',
        errorBack: 'Tú kann ikki fara aftur tá tú hevur innloyst gávukort',
        totalPrice: 'Total',
        currency: 'kr.',
        pay: 'Complete Payment',
        processing: 'Processing...',
        payZero: 'Complete checkout',
        payMethod: 'Paying method'
    }

    //new: {
    //    ...OrderFlowLocale.new,
    //    paymentMethod: '',
    //    giftCard: '  ❯',
    //    payWithReepay: '  ❯',
    //    creditCardForeign: '',
    //    errorCreditCard: '',
    //    errorBack: ''
    //}
}