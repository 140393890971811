import React, {  useState } from 'react'
import '../styles/program.scss'
import { ROUTES } from '../../navigation/route-constants'
import moment from 'moment'

export default function ShowtimeProgram({ showtime, movie, index, date_text }) {
    let opensAt = null
    const [minutes, setMinutes] = useState(null)
    const [seconds, setSeconds] = useState(null)

    const efter_end = moment().isAfter(showtime.webEnable.period.end)
    let buy_end 
    let isInsideShowPeriod = moment().isBetween(showtime.webEnable.period.start, showtime.webEnable.period.end);
    let hidden = !isInsideShowPeriod
 
        const dateTimeNow = moment();
    
        if (hidden) {  
              opensAt = moment(showtime.webEnable.period.start)
          }
       
        else {
            opensAt = moment(showtime.webEnable.buyPeriod.start)

            const isNotDefault = !opensAt.isSame('0001-01-01', 'day');

            if (moment().isSame(showtime.webEnable.buyPeriod.start, 'day') === true && showtime.webEnable._buy === 1 && isNotDefault) {
                setCountDown(opensAt)
                buy_end = moment().isAfter(showtime.webEnable.buyPeriod.end)
            }
        }
   

    let countDownFrom = 60;
  

    function setCountDown(opensAt) {
    let    countDown = setInterval(() => {
            const dateTimeNow = moment();
            const diff = opensAt.diff(dateTimeNow);
            const duration = moment.duration(diff);
        
            if ( duration.asMinutes() <= countDownFrom) {
              
                if (minutes === 0 && seconds === 0) {
                    clearInterval(countDown);
                    setMinutes(null)
                    setSeconds(null)

                }
                else {
                    setMinutes(duration.minutes())
                    setSeconds(duration.seconds())

                }
            }
    }, 1000);
        if (minutes < 0) {
            clearInterval(countdown)
        }
    }

      let disable
    function generateCountdown(opensAt, dateTimeNow) {
        let countdown = null
    
        if (opensAt != null && opensAt > dateTimeNow) {
            
          disable= true
            countdown = minutes == null && seconds == null ? <div className="count-down">Aktiv d.{opensAt.format('DD/MM HH:mm')}</div> : null
            if (countdown == null) {
                countdown = minutes != null && seconds != null && opensAt.isSame(dateTimeNow, 'day')  ?
                <div className="count-down">Aktiv om {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div> 
                    : null
            }
        }
     
        return countdown
    }

  
    const text = efter_end === true || buy_end === true ? 'Køb er lukket'
        : isInsideShowPeriod === true ? date_text  : ''
    
    let countdown = showtime.webEnable._buy === 1 && generateCountdown(opensAt, dateTimeNow) 
    return (

        <div>
        
            <a  style={{
                backgroundImage: efter_end === true || buy_end === true ? 'none' : 'linear-gradient(to top, #950200, #9e0200)',
                padding: showtime.activ_text ? '' : '5px'
            }}
                className={`showtime_buttons ${efter_end === true || disable === true || buy_end === true ? 'disabled' : ''}`}
                href={`${window.location.origin}${ROUTES.ORDERFLOW.TICKETS}/${movie.movieNo}/${movie.showtimeArr[index]}?org=${movie.org}`}
            > {text} {countdown }
               

                
            </a>
        </div>)
}