import OrderFlowLocale from '../OrderFlowLocale'
import SummaryLocale from '../summary/SummaryLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        ...SummaryLocale.da,
        longDateTimeFormat: 'dddd [d.] LL [kl.] LT',
        buyTitle: 'Dit Køb',
        redeemGiftcard: 'Indløs gavekort',
        currency: 'kr.',
        title: 'Gavekort',
        total: 'Sum',
        next: 'Næste',
        back:'❮  Tilbage',
        payMethod: 'Betalingsmetode',
        pay: 'Gennemfør betaling',
        payZero: 'Gennemfør Køb',
        processing: 'behandling..',
        clubCard: 'klubkort',
        extraPurchase: 'ekstra købe ',

        info: 'Du kan indtaste flere kuponnumre, når du har tastet det første og klikket indløs. <br /> Hvis du indløser et saldo gavekort og der er et restbeløb tilbage, vil du kunne bruge dette senere, med samme gavekort nummer.'
    },

    en: {
        ...OrderFlowLocale.en,
        ...SummaryLocale.en,
        longDateTimeFormat: 'dddd LL [at] LT',
        redeemGiftcard: 'Redeem giftcard',
        buyTitle: 'Your Purchase',
        currency: 'DKK',
        total: 'Total',
        payMethod: 'Paying method',
        pay: 'Complete Payment',
        payZero: 'Complete checkout',
        processing:'processing..',
        title: 'Gift Card',
        next: 'Next',
        back: 'Back',
        clubCard: 'Club card',
        extraPurchase:'extra purchase ',
        info: 'You can add more cupon numbers after you have input and submitted the first one. <br /> If you submit a gift card and there is any remaining balance, you will be able to use this later with the same gift card number.'
    },

    fo: {
        ...OrderFlowLocale.fo,
        ...SummaryLocale.fo,
        longDateTimeFormat: 'dddd LL [d.] LT',
        buyTitle: 'Tín Keyp',
        currency: 'kr.',
        total: 'Total',
        payMethod: 'Paying method',
        pay: 'Complete Payment',
        payZero: 'Complete checkout',
        next: 'Next',
        back: 'Back',
        clubCard: 'Club card',
        extraPurchase: 'extra purchase ',

        redeemGiftcard: ' Innloys gávukort',
        processing: 'processing..',
        title: 'Gávukort',
        info: 'Tú kann nýta fleiri virðisseðla nummur, eftir at tú hevur skriva fyrstu kotuna inn og trýst á innloys. <br /> Er talan um saldo gávukort, og tað enn er peningur tilgóðar, kann tú nýta teir seinni við sama gávukort nummari.'
    }

    //new: {
    //    ...OrderFlowLocale.new,
    //    ...SummaryLocale.new,
    //    title: '',
    //    info: ''
    //}
}