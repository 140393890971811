import React, { useEffect, useState,} from 'react'
import '../styles/program.scss'
import _, { forEach, groupBy, includes } from 'lodash'


import moment from 'moment'

import { useHistory } from 'react-router-dom'

//import check from "./images/check-mark.svg";
//import checkIcon from "./images/check-mark.svg";
import Region from './Region';
import Cinema from './Cinema';
//import Sorting from './Sorting';
//import LOCALES from '../overlays/OverlayLocales'


import { useLocation } from "react-router-dom";





export default function AllFutureFilmsContainer({ allMovies, allEvents, allOrganizers, locations, ageCensor }) {
    const location = useLocation();
    
    const history = useHistory();
   
    const [showCalendar, setShowCalendar] = useState(false)
   // const [selectedMovieId, setSelectedMovieId] = useState('')
   // const [chosenRegion, setChosenRegion] = useState([]);
   // const [chosenCinema, setChosenCinema] = useState([]);
    const [noFilmError, setNoFilmError] = useState([]);
    const [calendarDate, setCalendarDate] = useState(new Date());
   // const [ filteredMovies, setFilteredMovies] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment().format('DD-MM-YYYY'))
    const [selected, setSelected] = useState(1);

    //const [filteredFilm,setFilteredFilms] = useState([])
    const [isOpen, setIsOpen] = useState(false);
  //  const [sortedBy, setSortedBy] = useState('Alfabetisk sorteret');
   // const [sortID, setSortID] = useState(0)
    const [cinemaID, setCinemaID] = useState(0)
    const [orgPerRegion, setOrgPerRegion] = useState([])
    const [resetCinema, setResetCinema] = useState(false)
    const [regarr, setRegarr] = useState([])
    const [cinemaarr, setCinemaarr] = useState([])
    const [cinemaIds, setCinemaIds] = useState([])
   // const [sortedEventList, setSortedEventList] = useState([{}])
   // const [movietoday, setMovietoday] = useState([{}])
    const [sortedEvents, setSortedEvents] = useState([])
    let evs = []
    let ev = []


    let filteredMovies 
    let am=[]
    allMovies.map((movie) => {
        if (moment(movie.openingDate).isAfter()) {
           am.push({ movie })
        }
    })
    
  
    // ----------Calculating MOST PROGRAMMED MOVIE---------------
    let moviesWithEvents = []
   
    function findOcc(arr, key) {
     
        let arr2 = [];

        arr.forEach((x) => {

            // Checking if there is any object in arr2 
            // which contains the key value 
            if (arr2.some((val) => { return val[key] == x[key] })) {

                // If yes! then increase the occurrence by 1 
                arr2.forEach((k) => {
                    if (k[key] === x[key]) {
                        k["occurrence"]++
                    }
                })

            } else {
                // If not! Then create a new object initialize  
                // it with the present iteration key's value and  
                // set the occurrence to 1 
                let a = {}
                a[key] = x[key]
                a["occurrence"] = 1
                arr2.push(a);
            }
        })

        return arr2, moviesWithEvents.push(arr2)

    }
   
    

    /**----------CATCHING ERROR HERE IF CERTAIN BIOGRAF DOES NOT HAVE MOVIE ON SELECTED DATE---------- */
    useEffect(() => {
        if (cinemaIds.length && !ev.length) {
            setNoFilmError('ingen film hos valgte biograf i valget dato')
        }
        else setNoFilmError('')
        }, [cinemaIds, ev])
 

    useEffect(() => {
        if (regarr.length > 0 && !evs.length) {
            setNoFilmError('ingen film i valgte region')
        }
    }, [evs, regarr])

   
    /**------Gettting the filtered regions here ------------- */
    let filteredRegion = []  // contains filtered regions from choose region field
    regarr.forEach(checkReg);
    
    function checkReg(item) {
        allOrganizers.filter(org => {
            if (org.region == item) filteredRegion.push(org)
        })
    }
  
    allEvents.map(e => {
        filteredRegion.map(reg => {

            if ((reg._no === e._organizerNo) ) {
                evs.push(e)             
            }
        })
    })
   // console.log(evs, 'evs')
    useEffect(() => {
        evs && setSortedEvents(evs)
    }, [ regarr])
   // console.log(evs, 'evs')


    /**-----GETTING FILTERED BIOGRAFS HERE------- */
    let filteredCinemas = []
    cinemaIds.forEach(checkCinemaIds)

    function checkCinemaIds(item) {
        allOrganizers.filter(org => {
            if (org._no === item) filteredCinemas.push(org)
        })
    }
  
        allEvents.map(event => {
            filteredCinemas.map(cinema => {
                if (event._organizerNo === cinema._no ) {
                
                    ev.push(event)
                }
            })
        })

    useEffect(() => {
        ev.length && setSortedEvents(ev)
    }, [cinemaIds, ])
  
    //---------chosen region stored in session storage--------
    //regarr.length && regarr.map((reg) => {
    //    sessionStorage.setItem(reg, 'region')
    //})  
   // sessionStorage.setItem()
    /**---- FILTERING FILM  WITH SELECTED DATE --- */
   // let showsPerDay = [{}]
   
    let comingFilmLists = [{}]
   
    let eventsForFilter = (cinemaIds.length || regarr.length) && sortedEvents.length ? sortedEvents : allEvents
   // console.log(eventsForFilter)
    let eventsPerDay = [{}]
    eventsForFilter.map((e) => {
        // console.log(moment(e.dateTime).format('DD-MM-YYYY'))
       // if (moment(e.dateTime).format('DD-MM-YYYY') === selectedDate) {
          
              allMovies.map((m) => {
               
                  if (m._no === e._movieNo && moment(m.openingDate).isAfter()) {
                      comingFilmLists.push(m)
                       // showsPerDay.push(m)
                        eventsPerDay.push(e)
                    }           
               
            })
      //  }
    })

   
   
    filteredMovies = toFindDuplicates(comingFilmLists)
   // console.log(eventsPerDay, 'events ')
   // console.log(filteredMovies, 'filtered movies')
    
    //console.log(ev, 'ev')
    filteredMovies.map((movie) => {
        eventsPerDay.map((ev) => {
            //let hasShows = false
            if (ev._movieNo === movie._no) {
                //hasShows = true
                 //console.log(movie)
                Object.assign(movie, { hasShows: movie._no == ev._movieNo ? true : false })

            }

        })

    })
    
    findOcc(eventsPerDay, '_movieNo')  // findOcc function counts how many times a movie occurs in allEvents
  //  console.log(eventsPerDay)
    //find duplicates
  
    function toFindDuplicates(showsPerDay) {
        const uniqueElements = new Set(showsPerDay);

        showsPerDay.filter((item) => {
            if (uniqueElements.has(item)) {

            } else {
                return item;
            }
        });

        return [...new Set(uniqueElements)];
    }

  
  //  const showsToday = toFindDuplicates(showsPerDay) 

  
    
    function handleArrrow() {
        setIsOpen((current) => !current);
    }

    // ----------FILTERING MOVIES WITH CHOOSE REGION----------------- 
  //  console.log(orgPerRegion, 'orgs per region')
    let orgsArr = []

    useEffect(() => {
        orgPerRegion && orgPerRegion.map((org) => {
            // console.log(org._no)
            orgsArr.push(org._no)
        })
    })


    let arr = [];

    allOrganizers.filter((i) => arr.push(i.region))

    //function toFindDuplicates(arr) {
    //    const uniqueElements = new Set(arr);
    //    // const filteredElements =
    //    arr.filter((item) => {
    //        if (uniqueElements.has(item)) {
    //            // uniqueElements.delete(item);
    //        } else {
    //            return item;
    //        }
    //    });

    //    return [...new Set(uniqueElements)];
    //}

   // const regions = toFindDuplicates(arr);


    function handleRegionArrrow() {
        setIsOpen((current) => !current);
    }

    function toggleCalendar() {
        setShowCalendar(true)
    }
    function closeCalendar() {
        setShowCalendar(false)
    }

    /*** folowing function runs when clicked on FLERE DATOER */
    function handleDateClick(e) {
    
        setCalendarDate(new Date(e))
       // console.log(moment(e).format('DD-MM-YYYY'))
        setSelectedDate(moment(e).format('DD-MM-YYYY'))
        closeCalendar();
        setSelected(0)
    }

    function movieArr(id) {
   //  console.log(id)
        let mo = []
        allMovies.map(m => {
            if (m._no == id) {
                mo.push( m )
               
            }
        })
        return mo
    }

   // console.log(allEvents, 'all events')
   // console.log(eventsForFilter, 'events for filter')
    //const [selectedMovie, setSelectedMovie] = useState()
    function handleMovieClick(e, movie) {   
        const movieData = movieArr(e.target.id)
      //  console.log(movieData, 'movie data')
        history.push('/film-details', { movieData, eventsForFilter, allOrganizers })
    }

  //  console.log(eventsForFilter, 'events for filter')

    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true;
    }
    filteredMovies.map((movie) => {
        // if (movie.censoring) {
        let n = movie.censoring
        const mc = isEmpty(movie.censoring) === false ? _.find(ageCensor, (icon) => { return n.includes(icon.id) }) : null
        
        Object.assign(movie, { censorImage: isEmpty(movie.censoring) === false ?  mc.image : null})
       // }
    })

    return (
        <div className='AllMovies' style={{ backgroundColor: '#2c2c2c' }}>
            <section className='FutureFilmsSection'>
                <div className='FutureFilmsHeader'>
              
                <Region allOrganizers={allOrganizers} setOrgPerRegion={setOrgPerRegion}
                        setResetCinema={setResetCinema} regarr={regarr} setRegarr={setRegarr} />
                    <p style={{width:'22px'} }></p>
                 <Cinema
                        allOrganizers={filteredRegion.length > 0 ? filteredRegion : allOrganizers} cinemaIds={cinemaIds} setCinemaIds={setCinemaIds}
                        setCinemaID={setCinemaID} resetCinema={resetCinema} setResetCinema={setResetCinema} regarr={regarr} cinemaarr={cinemaarr}
                            setCinemaarr={setCinemaarr} />
                    
              </div>

            </section>

            <p className='futureMovies_title'>Kommende film</p>
         
            <div className='FutureMoviesContainer'>
                {filteredMovies ? filteredMovies.sort((a, b) => a.name > b.name ? 1 : -1).map((movie, index) => { 

                    if (movie.name) {
                        return <div className='FutureMovieListItem' key={index} >

                            <div id={movie._no} className='poster'> <img src={'https://poster.ebillet.dk/' + `${movie.largePath}`} alt="movie-banner" />
                                <div className='age-banner'> {isEmpty(movie.censoring) === false ? <img src={movie.censorImage} alt='age-banner' /> : null}
                                </div>
                            </div>

                            <div className='FutureMovieDesc'>
                                <div className='movieInfo'>
                                    <p className='movie-name'
                                       
                                    >{movie.name}</p>
                                    <p style={{ color: '#000', fontWeight: 300, letterSpacing: '0.53px', fontSize: '16px' }}>
                                        Premiere : <span style={{ color: '#151515', fontWeight: 600 }}>{moment(movie.openingDate).format('LL')}</span></p>
                                    <p style={{ color: 'transparent', marginTop: '-15px' }}>Forventet re-premiere</p>

                                    <p className='movie_desc' style={{}}> {isEmpty(movie?.synopsis) === false ? movie.synopsis : ''} </p>
                                    <button id={movie._no} onClick={(e) => handleMovieClick(e,movie)} style={{
                                        border: 'none', outline: 'none', color: 'white', height: '31px', width: '105px', fontSize: '16px',
                                        backgroundImage: 'linear-gradient(to top, #950200, #9e0200)', position: 'absolute', bottom: '24px'
                                    }}>{movie.hasShows === true ? 'Billetter' : 'Læs mere'}</button>
                                </div>
                            </div>
                        </div>
                    }
                })
                    : ''}
            </div>
        </div>
                           
                 
            )
               
            


        
    
}