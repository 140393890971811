import './giftcards.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import { hasDecimals, withTwoDecimals } from '../../../utilities/validation-utilities'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { ROUTES } from '../../navigation/route-constants'
import VoucherDisplayAlta from '../vouchers/VoucherDisplayAlta'
import VoucherDisplay from '../vouchers/VoucherDisplay'

import VoucherInput from '../vouchers/VoucherInput'
import VoucherErrors from '../vouchers/VoucherErrors'
import { VOUCHER_TYPES } from '../../../api/api-constants'
import LOCALE from './GiftCardLocale'
import { appHeightChanged } from '../../../actions/app-actions'

class GiftCardContainer extends React.Component {
    constructor() {
        super();
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
    }

    navBack() {
        const { history } = this.props;
        history.goBack();
    }

    navNext() {
        const { organizerId, showtime, transactionId, customer, actions, totalPrice, history, giftCardAmount } = this.props;

        if (giftCardAmount > 0 && giftCardAmount < totalPrice && customer.creditCard || totalPrice === 0) {
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    if (totalPrice === 0) {
                        actions.book(transactionId, 'Free', totalPrice)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    } else history.push(`${ROUTES.ORDERFLOW.DIBS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
                }
            });
        } else history.push(`${ROUTES.ORDERFLOW.PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    render() {
        const { bottomBarAllwaysVisible, appLocale, totalPrice, selected, vouchers, actions, voucherConstraints, selectedMovieName, selectedMovieDate, showtime, warning_texts, giftCardEnabled } = this.props;
        const showWithDecimals = hasDecimals(selected.totalTicketPrice) || hasDecimals(selected.totalFees) || hasDecimals(selected.totalExtraPurchasesPrice) || hasDecimals(selected.giftCardAmount) || hasDecimals(totalPrice);
       
        const dateTime = moment(showtime.dateTime);
        
        const totalTktPrice = selected.totalTicketPrice

        const ticketPriceAsText = showWithDecimals ? withTwoDecimals(selected.totalTicketPrice) : selected.totalTicketPrice;
        const feesAsText = showWithDecimals ? withTwoDecimals(selected.totalFees) : selected.totalFees;
        const extrasPriceAsText = showWithDecimals ? withTwoDecimals(selected.totalExtraPurchasesPrice) : selected.totalExtraPurchasesPrice;
        const totalPriceAsText = showWithDecimals ? withTwoDecimals(totalPrice) : totalPrice;
        const clubCardPoints = selected.clubCardPoints ? <p>{LOCALE[appLocale].clubCard} <span>{selected.clubCardPoints} point</span></p> : null;

        const todays_date = moment(new Date()).format('DD-MM-YYYY')
        const showtime_date = moment(dateTime).format('DD-MM-YYYY')
       
        const today_warning = warning_texts.filter(item => { return item.id == 'warning_buy_today' })
        const future_warning = warning_texts.filter(item => { return item.id == 'warning_buy_future' })

        const warningText = todays_date === showtime_date ? today_warning : future_warning
      
        const warn_text = warningText.length > 0 ? appLocale == 'en' || appLocale == 'fo' ? warningText[0].en : warningText[0].da : ''
        const disableGiftcardDiv = giftCardEnabled === false || voucherConstraints.length === 0 ? true : false
      
        return (
            <div className="alta_giftcardContainer" > 
                <div className="container">
                    <p className='giftcardContainer_title'> {LOCALE[appLocale].buyTitle}</p>
                   
                    <p style={{ marginBottom: 0, fontWeight: 600 }} className='movie_desc'>{selectedMovieName}</p>
                    <p style={{ margin: 0, fontWeight: 800 }} className='movie_desc'>{_.capitalize(dateTime.format(LOCALE[appLocale].longDateTimeFormat))}</p>
                    <p className='alertinfo' dangerouslySetInnerHTML={{ __html:  warn_text }}></p>

                    <div className="overview">
                        <p>{LOCALE[appLocale].tickets} <span style={{ float: 'right' }}>{ticketPriceAsText} {LOCALE[appLocale].currency}</span></p>

                        <VoucherDisplay type={VOUCHER_TYPES.TICKETS}
                            appLocale={appLocale}
                            tickets={selected.tickets}
                            vouchers={vouchers}
                            vouchersChanged={actions.vouchersChanged}
                        />

                        <p>{LOCALE[appLocale].fees} <span style={{ float: 'right' }}>{feesAsText} {LOCALE[appLocale].currency}</span></p>
                        <p className='extra'>{_.capitalize(LOCALE[appLocale].extraPurchase)} <span style={{ float: 'right' }}>{extrasPriceAsText} {LOCALE[appLocale].currency}</span></p>

                        <VoucherDisplayAlta type={VOUCHER_TYPES.GIFTCARD}
                            appLocale={appLocale}
                            vouchers={vouchers}
                            showWithDecimals={showWithDecimals}
                            vouchersChanged={actions.vouchersChanged}
                        />

                        <hr />
                        <p className='sum_container'> {LOCALE[appLocale].total} <span style={{ float:'right' }}>{totalPriceAsText} {LOCALE[appLocale].currency}</span></p>
                       {/* {clubCardPoints}*/}
                    </div>
                    {disableGiftcardDiv === false ?        // if disableGiftCardEnabled is true OR voucherConstraints list is empty, redeem giftcard will disappear
                        <>
                    <p className='giftcard_title' > {LOCALE[appLocale].redeemGiftcard}</p>
                    <div style={{ position: 'relative', }}>
                        <VoucherInput type={VOUCHER_TYPES.GIFTCARD}
                            appLocale={appLocale}
                            vouchers={vouchers}
                            voucherConstraints={voucherConstraints}
                            vouchersChanged={actions.vouchersChanged}
                            appHeightChanged={actions.appHeightChanged}
                            inputInfo={LOCALE[appLocale].info}
                           
                    />
                            </div>
                        </>
                        : ''}
                    <VoucherErrors errors={vouchers.errors} />
                </div>
              

               
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        bottomBarAllwaysVisible: state.organizer.configuration.bottomBarAllwaysVisible,
        bioKlubDk: state.order.bioKlubDk,
        totalPrice: state.order.totalPrice,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        appLocale: state.app.locale,
        selected: state.order.selected,
        vouchers: state.order.selected.vouchers,
        tickets: state.order.selected.tickets,
        giftCardAmount: state.order.selected.giftCardAmount,
        voucherConstraints: state.order.voucherConstraints,
        iframeId: state.app.iframeId,
        selectedMovieName: state.movielist.selectedMovie.name,
        selectedMovieDate: state.movielist.selectedDate,
        showtime: state.movielist.selectedShowtime || {},
        giftCardEnabled: state.organizer.configuration.giftCardsEnabled,
      
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardContainer)