import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import api from '../../../api/reepay-api'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import { SITE_TYPES } from '../../../api/api-constants'
import xml2js from '../../../lib/xml-to-js'
import CONSTANTS from '../orderflow-constants'



class ReepayContainerMulti extends React.Component {

    constructor() {
        super();
        this.state = { error: '' }
        this.state = { errorNo: '' }
        this.state = { errorCode: '' }

        this.navBack = this.navBack.bind(this)


    }

    componentDidMount() {
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods   } = this.props
       
        const querystrings = getParams();
       // console.log(this.props.location)
        const preBook = this.props.location.state.preBook_parameters;
        const license = sessionStorage.getItem('license') ? sessionStorage.getItem('license').replace(/\s/g, '') : '';

        //console.log(preBook, 'pre book')
        const transId = this.props.location.state.transactionId
       // console.log(CONSTANTS.fetchUrl, CONSTANTS.ticketgatePath)
       // transId && transactionId = transId
       // console.log(transactionId, 'trans id')
        const eventId = window.location.href.split('reepayMulti/')[1].split('?')[0]
        const search = window.location.search;

        const paramss = new URLSearchParams(search);
       
        const org = paramss.get('org')

        if (!querystrings.cancel && !querystrings.invoice) {

           // fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/PreBook_Multi?TransactionID=${transId}&UserID=&Phone=${customer.phone}&Name=${customer.name}&Password=${customer.password}&EMail=${customer.email}&CardHolder=0&CreditCardCode=DK&QuickUser=0&Newsletter=&nSendReceipt=1&Zipcode=&Note=`, {
            //    method: 'GET',
            //    headers: {
              //      'UserName': 'web',
               //     'Password': 'web',
                 //   'OriginNo': '10',
              //  },
          //  })
             //   .then(res => res.text())
              //  .then(result => {

                 //   const json = xml2js.parseString(result)
                  //  const data = json.message
                  //  if (data && data.answer._result === false) { this.setState({ error: data.answer._niceMessage ? data.answer._niceMessage : data.answer._message }) }
                 //   if (data && data.answer._result === false) { this.setState({ errorNo: data.answer._error }) }
                 //   if (data && data.answer._result === false) { this.setState({ errorCode: data.answer._code }) }

                        // console.log(querystrings, 'strng')
                     //    console.log(data)
                //    if (data) {
                       // const preBook = data.dibs.parameters;

                        const type = siteType ? `&sitetype=${siteType}` : '';
                       // let callback = window.location.origin + `/reepayMulti/${eventId}?org=${organizerId}&transactionId=${transId}&amount=${preBook.amount}&iframeid=${iframeId}${type}`; //Book skal bruge transactionId og amount..!
                        //let calback = window.location.origin + `/reepay-multi-Landing?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transId}`
                        let calback = (window.location.origin.startsWith(`http://localhost`) ? `http://dev.ebillet.dk` : window.location.origin) + `/systemnative/ticketgate.asmx/KvitteringMulti?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transId}`

                       let acceptUrl = calback + `&accept=true&LicensePlate=${license}`;

                        let cancelUrl = calback + '&cancel=true';

                        if (siteType == SITE_TYPES.UMBRACO && origin) {
                            const base64Url = btoa(acceptUrl + `&id=&customer=${customer.phone}`);   // Dette tilføjes da iframe kaldet til ticketgate.asmx ellers ikke virker
                            const base64UrlC = btoa(cancelUrl + `&id=&customer=${customer.phone}`);  // Dette tilføjes da iframe kaldet til ticketgate.asmx ellers ikke virker
                            acceptUrl = `${origin}/reepay-landing-page/?iframeid=${iframeId}&iframeurl=${base64Url}`;
                            cancelUrl = `${origin}/reepay-landing-page/?cancel=true&iframeid=${iframeId}&iframeurl=${base64UrlC}`;
                        }

                        const reepayObject = {
                            order: {
                                handle: preBook.orderid,
                                customer: {
                                    first_name: customer.name,
                                    phone: customer.phone,
                                    email: customer.email,
                                    handle: customer.phone
                                },
                                amount: preBook.amount,
                                metadata: {
                                    
                                    eventID: eventId,
                                    organizerId: org,
                                    iframeUrl: calback
                                },
                            },
                            accept_url: acceptUrl,
                            cancel_url: cancelUrl
                        };

                        if (paymentMethods)
                            reepayObject.payment_methods = _.split(paymentMethods, ',');
                      

                        api.fetchSessionId(btoa(preBook.merchant), reepayObject).then((session) => {
                          
                           if (siteType == SITE_TYPES.UMBRACO && window.parent != window) {
                                console.log('[CHILD] Start Reepay! Posting to parent: ');
                                console.log({ iframeId, sessionId: session.id });

                                window.parent.postMessage({
                                    iframeId: iframeId,
                                    reepay: {
                                        sessionId: session.id
                                    }
                                }, '*');
                            } else {
                                new window.Reepay.WindowCheckout(session.id);
                            }
                            
                        });

                      
                      
                    //}
              //  })

           
       }  // if ends here

        // cancel reepay transaction
       else  if (querystrings.cancel) {
                const message = `transactionId: ${transId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
             // logTrace('ReepayContainer - CANCEL TRANSACTION', message);
            // localStorage.removeItem('beforeDibsState');
            history.push(`${ROUTES.MULTI_TICKETS}/${eventId}?org=${organizerId}`);
            // actions.resetOrder();

        }
      
        
        
        // complete reepay transaction
        else {
           
          
               // to receipt page if all is well
                history.push(`${ROUTES.FILMCLUB_RECEIPT}?org=${organizerId}`);
           
           

        } 

    }
   
    navBack() {
        const { organizerId, history } = this.props;
        const transId = this.props.location.state.transactionId

        fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/CancelTransaction_Multi?TransactionID=${transId}`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },
        })
            .then(res => {
                return res.text()
            })
            .then(result => {
                const json = xml2js.parseString(result)
                //console.log(json)
                history.push(`${ROUTES.FILMCLUB}?org=${organizerId}`)

            })
       // history.push(`${ROUTES.FILMCLUB}?org=${organizerId}`)
    }

    render() {
        

        const { error,  } = this.state;
        const { organizerId, history  } = this.props
        //console.log(organizerId)
        return (
            <div className="ReepayContainer">
               
                {error && <div style={{ backgroundColor: 'rgba(161, 161, 161, 0.95)', color: 'white', height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, bottom: 0, padding: '30px' }}>

                    <div style={{ backgroundColor: '#2e2e2e', textAlign: 'center', padding: '30px', width: '100%', margin: 'auto' }}>
                        <h3>{this.state.error}</h3>

                        <div style={{ textAlign:'center' }}>
                          <button onClick={this.navBack} style={{ backgroundColor: 'green', color: 'white' }}>Filmklub</button>
                        </div>
                        <p style={{ float: 'right', fontSize: '12px' }}> Error code : ( {this.state.errorCode}, {this.state.errorNo} )</p>

                    </div>
                </div>}

            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
   // console.log(state)
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        checkoutComplete: state.customer.checkoutComplete,
        iframeId: state.app.iframeId,
        siteType: state.app.siteType,
        origin: state.app.origin,
        paymentMethods: state.organizer.configuration.paymentMethods,

        //for session storage after reepay payment on seperate page
        sessionState: {
            organizer: state.organizer,
            movielist: {
                selectedMovie: { ...state.movielist.selectedMovie, versions: [] },
                selectedShowtime: state.movielist.selectedShowtime
            },
            order: { ...state.order, seatsImage: null },
            customer: state.customer,
            user: state.user
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators( dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReepayContainerMulti)