import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import api from '../../../api/reepay-api'
import { logTrace, sendLogSms } from '../../../api/orderflow-api'
import { resetOrder } from '../../../actions/order-actions'
import { preBook, book } from '../../../actions/customer-actions'
import { gaTrackCheckoutStep } from '../../../actions/ga-actions'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import { SITE_TYPES } from '../../../api/api-constants'
import xml2js from '../../../lib/xml-to-js'
import CONSTANTS from '../orderflow-constants'

class ReepayContainer extends React.Component {
 
    constructor() {
        super();
        this.state = { error: '' }
       
    }
 
    componentDidMount() {
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods } = this.props;
        const license = sessionStorage.getItem('license') ? sessionStorage.getItem('license').replace(/\s/g, '') : '';
        const querystrings = getParams();
        //  console.log(querystrings)
        // new reepay transaction
        if (!querystrings.cancel && !querystrings.invoice) {
            // localStorage.setItem('beforeDibsState', JSON.stringify(sessionState));
            //  const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            //   logTrace('ReepayContainer - NEW TRANSACTION', message);

            actions.preBook(transactionId, customer).then((result) => {
                // console.log(result)
                if (!result.error) {
                    const preBook = result.dibs.parameters;
                    const type = siteType ? `&sitetype=${siteType}` : '';
                    //console.log(type, 'type')

                    let callback = (window.location.origin.startsWith(`http://localhost`) ? `http://dev.ebillet.dk` : window.location.origin) + `/systemnative/ticketgate.asmx/Kvittering?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transactionId}`

                    // let callback = window.location.origin + `/reepay/${showtime.movieId}/${showtime.id}?org=${organizerId}&transactionId=${transactionId}&amount=${preBook.amount}&iframeid=${iframeId}${type}`; //Book skal bruge transactionId og amount..!
                    let acceptUrl = callback + `&accept=true&LicensePlate=${license}`;
                    let cancelUrl = callback + '&cancel=true';
                    // let cancelUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`

                    if (siteType == SITE_TYPES.UMBRACO && origin) {
                        const base64Url = btoa(acceptUrl + `&id=&customer=${customer.phone}`);   // Dette tilføjes da iframe kaldet til ticketgate.asmx ellers ikke virker
                        const base64UrlC = btoa(cancelUrl + `&id=&customer=${customer.phone}`);  // Dette tilføjes da iframe kaldet til ticketgate.asmx ellers ikke virker
                        acceptUrl = `${origin}/reepay-landing-page/?iframeid=${iframeId}&iframeurl=${base64Url}`;
                        cancelUrl = `${origin}/reepay-landing-page/?cancel=true&iframeid=${iframeId}&iframeurl=${base64UrlC}`;
                    }

                    const reepayObject = {
                        order: {
                            handle: preBook.orderid,
                            customer: {
                                first_name: customer.name,
                                phone: customer.phone,
                                email: customer.email,
                                handle: customer.phone
                            },
                            amount: preBook.amount,
                            metadata: {
                                showtimeId: showtime.id,
                                movieId: showtime.movieId,
                                organizerId: showtime.organizerId,
                                iframeUrl: callback
                            },
                        },
                        accept_url: acceptUrl,
                        cancel_url: cancelUrl,

                    };

                    if (paymentMethods)
                        reepayObject.payment_methods = _.split(paymentMethods, ',');
                    // console.log(reepayObject, 'reepay obj')

                    //  logTrace('REEPAY - SESSION ID REQUEST', JSON.stringify(reepayObject));

                    api.fetchSessionId(btoa(preBook.merchant), reepayObject).then((session) => {
                        // logTrace('REEPAY - SESSION ID SUCCESS', session.id);
                        if (siteType == SITE_TYPES.UMBRACO && window.parent != window) {
                            console.log('[CHILD] Start Reepay! Posting to parent: ');
                            console.log({ iframeId, sessionId: session.id });

                            window.parent.postMessage({
                                iframeId: iframeId,
                                reepay: {
                                    sessionId: session.id
                                }
                            }, '*');
                        } else {
                            new window.Reepay.WindowCheckout(session.id);
                        }
                    });
                }
            });
        }

        // cancel reepay transaction
       else  if (querystrings.cancel) {
            // const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            //logTrace('ReepayContainer - CANCEL TRANSACTION', message);
            //localStorage.removeItem('beforeDibsState');
           // actions.resetOrder();
            history.push(`${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`)
        }

        else ' '
   



        // complete reepay transaction
        //else if (checkoutComplete) {
        //    try
        //    {
        //        const link = sessionStorage.getItem('link')
        //        logTrace('ReepayContainer - COMPLETED TRANSACTION before...', '');

        //        const message = `transactionId: ${transactionId}, orderId: ${querystrings.invoice}, reepay sessionId: ${querystrings.id}, orgId: ${organizerId}, 
        //             customer email: ${customer.email}, customer phone: ${customer.phone}`;
        //        logTrace('ReepayContainer - COMPLETED TRANSACTION', message);
        //        localStorage.removeItem('beforeDibsState');

        //        // if there is link variable in the session storage then redirect to that link and send orderId along, otherwise redirect to flow's RECEIPT page
        //        link ? window.location.href = `${link}?orderid=${querystrings.invoice}` :
        //            history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        //    }
        //    catch(error)
        //    {
        //        logTrace('ReepayContainer - COMPLETED TRANSACTION Exception', error.message);
        //        sendLogSms("ReepayContainer - COMPLETED TRANSACTION Exception: " + error.message);
                
        //        throw error;
        //    }
            
        //}
       // console.log(showtime.movieId)
       // console.log(showtime.id)
    }

    render() {
        
        const { error } = this.state;
        return (
            <div className="ReepayContainer">
               
                <div className="error">{error}</div>
              
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        checkoutComplete: state.customer.checkoutComplete,
        iframeId: state.app.iframeId,
        siteType: state.app.siteType,
        origin: state.app.origin,
        paymentMethods: state.organizer.configuration.paymentMethods,

        //for session storage after reepay payment on seperate page
        //sessionState: {
        //    organizer: state.organizer,
        //    movielist: {
        //        selectedMovie: { ...state.movielist.selectedMovie, versions: [] },
        //        selectedShowtime: state.movielist.selectedShowtime
        //    },
        //    order: { ...state.order, seatsImage: null },
        //    customer: state.customer,
        //    user: state.user
        //}
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({ preBook }, dispatch)
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ReepayContainer)