import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMovieList, getAlltheData } from '../../actions/movielist-actions'
import { appHeightChanged } from '../../actions/app-actions'
import './styles/program.scss'
import Trailer from './components/Trailer'
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';



class AllTrailers extends React.Component {
   
    constructor() {
        super();
        this.state= {
            sortedBy: 'Alfabetisk sorteret',
            isOpen: false,
            trailerPlaying: false,
            selectedMovieId:0
        },
           // this.toFindDuplicates = this.toFindDuplicates.bind(this)
            this.handleMovieClick = this.handleMovieClick.bind(this)
            this.isEmpty = this.isEmpty.bind(this)
    }
    componentDidMount() {
        const { actions, movies, organizerIds, allOrganizers } = this.props
        if (movies.length == 0) {
            actions.getMovieList(3);
            actions.getAlltheData()
        }
       
    }
     isEmpty(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

    handleMovieClick(e) {
        const { allEvents, allOrganizers } = this.props
        this.state.selectedMovieId = e.target.id
    //setSelectedMovieId(e.target.id)
    //  setSelectedMovieName(e.target.name)
    let movie_id = e.target.id
    //let name = e.target.name
    //setSelectedMovieId(e.target.offsetParent.id)
    //console.log(movieId(movie_id))
    let clickedMovie = movieId(movie_id)
    const movieData = isBaseMovie(clickedMovie)
    // console.log(movieData, 'movie data')
    movie_id && history.push('/film-details', { movieData, allEvents, movie_id, allOrganizers, clickedMovie })
}

   // allOrganizers.filter((i) => arr.push(i.region))
   //// console.log(arr)
    //toFindDuplicates() {
    //    let arr = []
    //    const { allOrganizers } = this.props
    //   allOrganizers && allOrganizers.filter((i) => arr.push(i.region))
    //    const uniqueElements = new Set(arr);
    //    // const filteredElements =
    //    arr.filter((item) => {
    //        if (uniqueElements.has(item)) {
    //            // uniqueElements.delete(item);
    //        } else {
    //            return item;
    //        }
    //    });

    //    return [...new Set(uniqueElements)];
    //}

   // ;
   // console.log(duplicateElements)
    render() {
        const { allOrganizers, allMovies } = this.props
        console.log(allMovies)
        //const duplicateElements = this.toFindDuplicates()
        
        return (
            <div className='TrailersContainer' style={{ marginTop: allMovies ? '-30px' : '0px' }}> 
                {allMovies ? 
                    <>
                        <div className='trailer-header'>
                            <p className='title'>Trailere</p>

                            <div className='sorting' >
                                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.08 5.50024V16.64" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="1" x2="16.52" y2="1" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="6.40002" x2="11.12" y2="6.40002" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="11.8" x2="7.04" y2="11.8" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <path d="M11.16 11.96L16.08 16.88L21 11.96" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <div className=''>
                                    <div className='sortingContainer' >
                                        <div style={{
                                            padding: "5px 8px",
                                            boxSizing: "border-box",
                                            height: "31px",
                                        }}>
                                            <p style={{ margin: 0, }}>{this.state.sortedBy}</p>
                                            <span className='line'></span>
                                            <button
                                                onClick={() => handleArrrow()}
                                                style={{
                                                    position: "absolute",
                                                    bottom: "7px",
                                                    right: "-3px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "transparent",
                                                }}
                                            >
                                                <p className={this.state.isOpen ? "rotated" : ""}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                        <path d="M1 1L7.48151 8L13.963 1" stroke="#757575" />
                                                    </svg>
                                                </p>
                                            </button>
                                        </div>

                                        {this.state.isOpen &&
                                            <div className="sortingDiv">
                                                {sorting && sorting.map((item) => (
                                                    <p key={item.id} id={item.id} style={{ height: '15px' }} /*onChange={() => setSortID(item.id)}*/ onClick={() => handleSort(item)}>{item.name}</p>
                                                ))}

                                            </div>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                <section className='trailers'>
             
                            {allMovies.sort((a, b) => a.name > b.name ? 1 : -1).map((movie,index) => {
                                if (movie.vimeoId != null) {
                                    return <div key={index} style={{
                                        display: 'flex', justifyContent: 'space-between', height: '292px', marginBottom: '25px',
                                        width: '575px'
                                    }}>


                                        <img style={{ width: '196px' }} src={'https://poster.ebillet.dk/' + `${movie.largePath}`} alt="" />

                                        <div style={{ width: '379px', background: 'rgba(0, 0, 0, 0.45)' }}>
                                            <div className='trailerDiv' style={{ height:'213px' }}>
                                                <Trailer trailerUrl={`https://admin.ebillet.dk/trailers/${movie.vimeoId}.mp4`}
                                                    trailerPoster={`https://ebillet.dk/poster/advertisement/${this.isEmpty(movie.sliderNoText) === true ? movie.advertisement : movie.sliderNoText}`}
                                                   />
                                                {/*<Player className="player"*/}
                                                {/*    src={`https://admin.ebillet.dk/trailers/${movie.vimeoId}.mp4`}*/}
                                                {/*    poster={`https://ebillet.dk/poster/advertisement/${movie.sliderNoText}`}*/}
                                                {/*    preload="metadata"*/}
                                                {/*    ref={player => { this.player = player; }}*/}
                                                {/*>*/}
                                                {/*    <ControlBar>*/}
                                                {/*        <img className="logo" src="/images/logo.png" />*/}
                                                {/*    </ControlBar>*/}
                                                {/*</Player>*/}
                                            </div>
                                            {/* <video src="https://admin.ebillet.dk/trailers/29468D.mp4" alt="" /> */}
                                            <div className='movienameContainer' >
                                                <p className='movie_name' >{movie.name}</p>

                                                <button id={movie._no} onClick={(item) => this.handleMovieClick(item)} style={{
                                                    border: 'none', outline: 'none', color: 'white', height: '31px', width: '105px',
                                                    marginTop: '24px', backgroundImage: 'linear-gradient(to top, #950200, #9e0200)'
                                                }}>Billetter</button>
                                            </div>

                                        </div>
                                    </div>
                                }

                            }) }

              
              
        
                    
                        </section>
                    </>

           : <div className='spinner'></div>}
           
        </div>
    )
    }
}

const mapStateToProps = (state, ownProps) => {
    
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const movies = listConfig.date.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        movies,
        allMovies: state.movielist.allMovies,
        allEvents: state.movielist.allEvents,
        allLocations: state.movielist.allLocations,
        allMovieBases: state.movielist.allMovieBases,
        allOrganizers: state.movielist.allOrganizers
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getAlltheData, appHeightChanged, getMovieList }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllTrailers)