import React, {  useState } from 'react'
import 'video-react/dist/video-react.css';
import { useLocation } from "react-router-dom";
import moment from 'moment'
import DayPicker from 'react-day-picker';
import LOCALE from '../datepicker/DatePickerLocale'
import { useHistory } from 'react-router-dom'
import Trailer from './components/Trailer'
import ShowtimeProgram from './components/ShowtimeProgram';


export default function EbilletMovieContainer() {
    
    const [trailerPlaying,setTrailerPlaying] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    
    const history  = useHistory();
    const location = useLocation();
    const clickedMovie = location.state.clickedMovie
 
    const allEvents = location.state.eventsForFilter /*? location.state.eventsForFilter : location.state.allEvents*/
    const selectedMovieId = location.state.movie_id
    const movies = location.state.movieData
    const [movie, setMovie] = useState(movies?.length > 1 ? chosenMovie() : movies && movies[0])
    const already_selected_date = location.state.selectedDate
    const already_selectednr = location.state.selected
    const [selectedDate, setSelectedDate] = useState(already_selected_date ? already_selected_date : moment().format('DD-MM-YYYY'))
   // console.log(selectedDate, 'selected date')
   // console.log(location.state.selected, 'selected in ebillet movie cont')
    const [calendarDate, setCalendarDate] = useState(  new Date());
    const [selected, setSelected] = useState(already_selectednr ? already_selectednr : 1);
   // console.log(allEvents, 'all events')
    const orgsArr = location.state.allOrganizers
  
    function chosenMovie() {
        let movie 
        movies.map((mov) => {
            if (mov._no == selectedMovieId) {
                movie = mov              
            }
        })
        return movie
   } 
    function handleDateClick(e) {
        setCalendarDate(new Date(e))
        setSelectedDate(moment(e).format('DD-MM-YYYY'))
        closeCalendar();
      //  setSelected(0)
    }

        let eventsWithMovieArr = []
          allEvents &&  allEvents.map(ev => {
        
          
              if (ev._movieNo === movie._no &&  ev.webEnable._show === 1) {           
                eventsWithMovieArr.push(ev)
            }

        })

    function getUnique(arr, index) {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

   // console.log(eventsWithMovieArr)
    function makeShowtimeArr(org) {
        let showtimeNr = []
        eventsWithMovieArr.map(e => {
            if (org._organizerNo === e._organizerNo) {
                showtimeNr.push(e._no)
            }
        })
        return showtimeNr
    }

  
    function moviesShowtimes(org) {
        let showtimes = []
        eventsWithMovieArr.map(e => {
           
            if (org._organizerNo === e._organizerNo) {
                showtimes.push( e )          
            }
        })
        return showtimes
    }

   
    function findBioName(event) {
        let bio     
        let dt = event.dateTime && moment(event.dateTime).format('DD-MM-YYYY')
       
        orgsArr.map(org => {
            if (org._no === event._organizerNo && dt == selectedDate) {              
                bio = org.name
            }
        })
        return bio
    }

    function findOrgName(event) {
        let bio       
        orgsArr.map(org => {
            if (org._no === event._organizerNo ) {
                bio = org.name
            }
        })
        return bio
    }
   
    let movieAtBios = []
    for (let i = 0; i < eventsWithMovieArr.length; i++) {
        movieAtBios.push({
            bio_name: findBioName(eventsWithMovieArr[i]),
            org: eventsWithMovieArr[i]._organizerNo,
            movieShows: moviesShowtimes (eventsWithMovieArr[i]), 
            movieNo: eventsWithMovieArr[i]._movieNo,
            showtimeArr: makeShowtimeArr(eventsWithMovieArr[i])
        })
    }

    let orgsWithSelectedMovie = []
    for (let i = 0; i < eventsWithMovieArr.length; i++) {
      //  console.log(moviesShowtimes(eventsWithMovieArr[i]), 'event moviearr')       
        orgsWithSelectedMovie.push({
            bio_name: findOrgName(eventsWithMovieArr[i]),
            org: eventsWithMovieArr[i]._organizerNo,
            movieShows: moviesShowtimes(eventsWithMovieArr[i])/* eventsWithMovieArr.filter((ev) => { return ev._organizerNo === eventsWithMovieArr[i]._organizerNo })*/,
            movieNo: eventsWithMovieArr[i]._movieNo,
            showtimeArr: makeShowtimeArr(eventsWithMovieArr[i])
        })
    }
   

    const cinemasWithMovies = getUnique(orgsWithSelectedMovie, 'bio_name')  // alle-kommende-film
    const movieAtCinameOnDate = getUnique(movieAtBios, 'bio_name')  //ebillet-program

    eventsWithMovieArr.map(e => {
        movieAtCinameOnDate.map(mov => {
            mov.bio_name && mov.movieShows.map(m => {
                if (m === e.dateTime && moment(m).format('DD-MM-YYYY') === selectedDate) {
                 
                }
            })
        })
    })
  
    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true; 
    }
      
    const premiere = movie.openingDate ? <p className="premiere" style={{ marginTop: '15px', marginBottom: '10px' }}><span>Premiere: </span><label>{moment(movie.openingDate).locale('da').format('LL')}</label></p> : null;

    const actors =    movie.actors && <p>
            <span>Skuespillere:</span>
        <label >{Array.isArray(movie.actors.item) === true ?  _.map(movie.actors.item, (actor) => actor).join(', ') : movie.actors.item}</label>
    </p>
    
    const directors =  movie.directors && <p>
        <span>Instruktør:</span>
        <p> {Array.isArray(movie.directors.item) === true ? _.map(movie.directors.item, (actor) => actor).join(', ') : movie.directors.item}</p>
    </p>
   
    const genre =  movie.genre && <p>
            <span>Genre:</span>
            <p >{movie.genre} </p>
    </p>
    const length = movie.length && <p>
            <span>Længde:</span>
            <p > {movie.length}</p>
        </p>
    const censur = movie.censoring && <p>
            <span>Censur:</span>
            <p > {movie.censoring}</p>
    </p>
  
    //---------choose version -----
    const [selectedVers, setSelectedVers] = useState(0);
    function secondVersion() {
       
        let movie
        movies.map((mov) => {
            if (mov._no != selectedMovieId) {
                movie = mov
            }
        })
        return movie
    }

    const lists =
        clickedMovie ? 
        [
      {id: 0, title: clickedMovie[0].name.split('-')[1] ? clickedMovie[0].name.split('-')[1] : 'Originalsprog' },
      movies.length > 1 ?   { id: 1, title: secondVersion().name.split('-')[1] ? secondVersion().name.split('-')[1]  : 'Originalsprog'} : ''       

            ]
    : []
 

    const handleVersion = (row) => {
        setSelectedVers(row.id)
  
        movies.map((mov) => {
            if (mov._no != movie._no) {
                setMovie(mov)
             
            }
        })
    }

    //-------choose day -------------

    const daysPerWeek = [
        { id: 1, title: "I dag", },
        { id: 2, title: "I morgen", },
        { id: 3, title: moment().add(2, 'days').locale("da").format('ddd. DD/MM'),},
        { id: 4, title: moment().add(3, 'days').locale("da").format('ddd. DD/MM'),},
        { id: 5, title: moment().add(4, 'days').locale("da").format('ddd. DD/MM'),},
        { id: 6, title: moment().add(5, 'days').locale("da").format('ddd. DD/MM'),},
        { id: 7, title: moment().add(6, 'days').locale("da").format('ddd. DD/MM'),},
    ]

   
    function toggleCalendar() {
        setShowCalendar(true)
    }
    function closeCalendar() {
        setShowCalendar(false)
    }

    function handleDayClick(days) {
        setSelected(days.id)

        if (days.id === 2) { setSelectedDate(moment().add(1, 'days').format('DD-MM-YYYY')) }
        else if (days.id === 3) { setSelectedDate(moment().add(2, 'days').format('DD-MM-YYYY')) }
        else if (days.id === 4) { setSelectedDate(moment().add(3, 'days').format('DD-MM-YYYY')) }
        else if (days.id === 5) { setSelectedDate(moment().add(4, 'days').format('DD-MM-YYYY')) }
        else if (days.id === 6) { setSelectedDate(moment().add(5, 'days').format('DD-MM-YYYY')) }
        else if (days.id === 7) { setSelectedDate(moment().add(6, 'days').format('DD-MM-YYYY')) }

        else setSelectedDate(moment().format('DD-MM-YYYY'))
    }

    
    const trailerUrl = `https://admin.ebillet.dk/trailers/${movie.vimeoId}.mp4`
    
    const trailerPoster = `https://ebillet.dk/poster/advertisement/${movie.sliderNoText}`

    function navBack() {
        history.goBack()
    }
 
    return (
        <div className='film-details'>
            <main className='Movie' >
            <p style={{ fontSize: '30px', fontWeight: 600,color:'white' }}>Filmdetaljer</p>

                { movie.vimeoId != null &&
                    <div className='' style={{}}>
                    {trailerUrl ? <Trailer trailerUrl={trailerUrl} trailerPoster={trailerPoster} setTrailerPlaying={setTrailerPlaying} />
                        : null}


                </div>
                }
                <div className='info-container' style={{ marginTop: trailerPlaying === true ? '0px' : '0px' }}>
              
                <div className='Poster'>
                        <img className='filmPoster' src={'https://poster.ebillet.dk/' + `${movie.largePath}`} />
                        <div className='age-banner poster-banner'>{isEmpty(movie.censoring) === false ? <img src={movie.censorImage} alt='age-banner' /> : null}</div>
                </div>
                <div className='MovieInfo'>
                    <p className='film-title' style={{ fontSize: '25px' }}>{movie && movie.name}</p>
                        {premiere}
                        <p className='description'>{isEmpty(movie.synopsis) === false && movie.synopsis}</p>
                    <br/>
                    {actors}
                    {directors}
                    {isEmpty(movie.censoring) === false && censur}
                    {isEmpty(movie.genre) === false && genre}
                    {length}             
                </div>
               
            </div>
        </main>
            <section>
                {movie._movieBaseNo > 0 ? 
                <div className='chooseVersionContainer'> 
                    <div style={{ maxWidth: '1170px', margin: 'auto' }}>
                    <div style={{  display: 'flex', height: '51px',backgroundColor:'#404040' }}>
                        <p style={{ color: 'white', marginRight: '10px' }}> Vælg version</p>
                        {lists.map((list, index) => (
                            <button
                                // className={`butto butto_${index}`}
                                key={list.id}
                                onClick={() => handleVersion(list)}
                                style={{
                                   
                                    backgroundColor:
                                        list.id === selectedVers
                                            ? "white"
                                            : "inherit",
                                    color:
                                        list.id === selectedVers ? "#313131" : "#d2d2d2",
                                }}
                            >
                                {list.title}
                            </button>
                        ))}

                    </div>
                    </div>
                </div>
                    : ''}
                {clickedMovie ?
                <div className='chooseDayContainer'>
                    <div style={{ maxWidth: '1170px',margin:'auto' }}>

                        <div style={{ display: 'flex', paddingTop: '5px', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '22px',marginRight:'15px' }}></p>
                        <div>
                            {daysPerWeek.map((days, index) => (
                                <button key={days.id} className={`days-btns day_btns_${index}`}
                                    style={{ backgroundColor: days.id === selected && location.state.selected !== 0 ? '#fff' : '', color: days.id === selected && location.state.selected !== 0 ? '#323232' : "#d2d2d2" }}
                                    onClick={() => handleDayClick(days)}> {days.title}   </button>))}

                            <button className='days-btns' style={{  color:'#d2d2d2'}} onClick={toggleCalendar}> Flere datoer</button>
                            </div>
                            {showCalendar &&
                                <div className={showCalendar ? 'Calendar slide-down' : 'Calendar slide-up'} >
                                    <span className="icon-close" onClick={closeCalendar}></span>
                                    <DayPicker
                                        mode='single'
                                        // initialMonth={firstDate}
                                        // fromMonth={firstDate}
                                        // toMonth={lastDate}
                                        selectedDays={calendarDate}
                                        selected={calendarDate}
                                        onSelect={setCalendarDate}

                                         months={LOCALE.da.months} //This is only the display name of the from-to months, unfortunately not which months to include in the calendar. This can't seem to be done with this lib.
                                        // weekdaysLong={LOCALE[appLocale].weekdays}
                                         weekdaysShort={LOCALE.da.weekdaysShort}
                                        firstDayOfWeek={1}
                                        enableOutsideDays={false}
                                         selectedDays={selectedDate}
                                        disabledDays={{ before: new Date() }}
                                        onDayClick={handleDateClick}
                                        fixedWeeks
                                    />
                                    
                                </div>
                            }
                     </div>
                    </div>
                </div>
                    : ''}   

            </section>
         
            <section className='orgs_container'>
                {!clickedMovie ? 
                    cinemasWithMovies.map((movie, index) => (
                        movie.bio_name && <>
                            <div className='bio_name_container'>
                                <p className='bio_name'>{movie.bio_name}</p>
                            </div>
                            <div key={index} style={{ paddingLeft: '25px', backgroundColor: '#fff', display: 'flex', flexWrap:'wrap',paddingTop:'10px' }}>
                                {movie.movieShows.map((showtime, index) => (

                                    <ShowtimeProgram showtime={showtime} movie={movie} index={index} date_text={moment(showtime.dateTime).locale('da').format('DD MMMM') + ' ' + 'kl.' + ' ' + moment(showtime.dateTime).format('LT')} />
                                ))}

                            </div>
                        </>
                    ))
                    :
                    movieAtCinameOnDate && movieAtCinameOnDate.map((movie, index) => (
                    movie.bio_name && <>
                        <div  className='bio_name_container'>
                            <p className='bio_name'>{movie.bio_name}</p>
                        </div>
                            <div key={index} style={{ paddingLeft: '25px', backgroundColor: '#fff', display: 'flex', paddingTop: '10px', flexWrap: 'wrap', }}>
                            {movie.movieShows.map((showtime,index) => (

                                moment(showtime.dateTime).format('DD-MM-YYYY') === selectedDate &&
                                <ShowtimeProgram showtime={showtime} movie={movie} index={index} date_text={moment(showtime.dateTime).format('LT')} /> 
                              
                                  
                                    
                                ))}
                         
                        </div>
                   </>
                    )
                )}
               
            </section>

            <br></br>
            <br></br>
            <div className='back_btn_cont'> <span className='back_arrowback'><svg xmlns="http://www.w3.org/2000/svg" width="3" height="6" viewBox="0 0 3 6" fill="none">
                <path d="M0 3L3 0V6L0 3Z" fill="white" />
            </svg></span> <button onClick={navBack} className='back_btn'>
                Tilbage til oversigt</button> </div>
            <br></br> 
            </div>
    )
}