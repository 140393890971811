import React, { useEffect, useState } from 'react'
import '../styles/program.scss'
//import DatePickerContainer from '../datepicker/DatePickerContainer'
//import CONSTANTS from '../movielist-constants'
//import xml2js from '../../lib/xml-to-js';
//import DayPicker from 'react-day-picker';
//import moment from 'moment'
//import downarrow from './images/down-arrow-backup-2-svgrepo-com (1).svg'
import check from "../images/check-mark.svg";
//import checkIcon from "./images/check-mark.svg";


export default function Region({ allOrganizers, setOrgPerRegion, regarr, setRegarr  }) {
   //const [chosenRegion, setChosenRegion] = useState([]);
   

    const [isOpen, setIsOpen] = useState(false);
   // const [chosenRegion, setChosenRegion] = useState('Vælge region');
  // console.log(chosenRegion)
    let arr = [];

    allOrganizers.filter((i) => arr.push(i.region))
 
    function toFindDuplicates(arr) {
        const uniqueElements = new Set(arr);
        // const filteredElements =
        arr.filter((item) => {
            if (uniqueElements.has(item)) {
                // uniqueElements.delete(item);
            } else {
                return item;
            }
        });

        return [...new Set(uniqueElements)];
    }

    const regions = toFindDuplicates(arr);
    

    function handleRegionArrrow() {
        setIsOpen((current) => !current);
    }

    function handleCheck(item) {
       // console.log(item, 'item')
        //setChosenRegion([e])
        if (regarr.includes(item)) {

            setRegarr(regarr.filter(r => { return r !== item }))
            //regarr.pop(item.name)
        }
        else setRegarr([...regarr, item])

    }
    useEffect(() => {
      regarr && setOrgPerRegion([...regarr])
    }, [])
   
       // setIsOpen(false);

        //const sortetOrg = _.filter(allOrganizers, (org) => { return org.region === e })
        //sortetOrg.length && setOrgPerRegion(sortetOrg)
        //setResetCinema(true)
    
 
    return (
        
                <div className="region">
                    <div  >
                        <p className="region-title">Vælg region</p>
                        <div className="chooseRegion">
                            <div
                                style={{
                                    position: "relative",
                                    padding: "5px 8px",
                                    boxSizing: "border-box",
                                    height: "31px",
                                }}
                            >
                        <p style={{ margin: 0 }}>{ regarr && regarr?.length === 1 ? regarr[0] : regarr?.length > 0 ? 'Valgte regioner' : 'Vælge region'}</p>
                        <span className="one"> {regarr?.length > 0 && regarr?.length}</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        height: "31px",
                                        right: "35px",
                                        borderLeft: "0.1px solid #757575",
                                    }}
                                ></span>
                                <button
                                    onClick={handleRegionArrrow}
                                    style={{
                                        position: "absolute",
                                        bottom: "8px",
                                        right: "-2px",
                                        border: "none",
                                        outline: "none",
                                        color:'grey',
                                        background: "transparent",
                                        
                                    }}
                                >
                                    <p className={isOpen ? "rotated" : ""}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                    <path d="M1 1L7.48151 8L13.963 1" stroke="#757575" />
                                </svg>
                                    </p>
                                </button>
                            </div>
                            {isOpen && (
                                <div
                                    style={{
                                        borderTop: "1px solid #757575",
                                        backgroundColor: "#3a3a3a",
                                        paddingBottom:'10px',
                                        zIndex: 100
                                    }}
                        >
                            {regions && regions.map((item,index) => (
                                <div key={index } style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "31px",
                                    padding: "8px",
                                }} onClick={()=> handleCheck(item)}>
                                    <p style={{ margin: 0, color: regarr.length && regarr.includes(item) ? '#fff' : '#999' }}>{item}</p>
                                    <p
                                        className="checkCheckbox"
                                        style={{ position: "relative", top: "-15px" }}

                                    >
                                        {regarr.length && regarr.includes(item) ?
                                            <img
                                                style={{ position: "absolute", top: "2px", left: "3px" }}
                                                src={check} alt=""
                                            />

                                            : ''}
                                    </p>
                                </div>
                            ))
                            }
                           
                                </div>
                            )}
                        </div>
                    </div>
                    
                </div>
               

           
        )
}