
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMovieList, getAlltheData } from '../../actions/movielist-actions'
import { appHeightChanged } from '../../actions/app-actions'
import { useHistory } from 'react-router-dom'
import EbilletProgram from './components/EbilletProgram'



class EbilletHome extends React.Component {
    constructor() {
        super();
       

    }
    componentDidMount() {
        const { actions, movies, organizerIds } = this.props
        if (movies.length == 0) {
            actions.getMovieList(3);
            actions.getAlltheData()
        }
    }
    
    render() {
        const { allEvents, allLocations, allMovieBases, allMovies, allOrganizers, ageCensor } = this.props;
        
       
        return (
            <div style={{ backgroundColor: '#303030' }}>


                {allEvents && allLocations && allMovies && allOrganizers ?
                    <EbilletProgram allMovies={allMovies} allEvents={allEvents} allOrganizers={allOrganizers} locations={allLocations} ageCensor={ageCensor} />
                    : <div className="spinner"></div>
                }
            </div>
         )
    }
}

const mapStateToProps = (state, ownProps) => {
   // console.log(state, 'state')
    // console.log(state.movielist, 'state movielist')
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const movies = listConfig.date.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        movies,
        allMovies: state.movielist.allMovies,
        allEvents: state.movielist.allEvents,
        allLocations: state.movielist.allLocations,
        allMovieBases: state.movielist.allMovieBases,
        allOrganizers: state.movielist.allOrganizers,
        ageCensor: state.movielist.ageCensor

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getAlltheData, appHeightChanged, getMovieList }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EbilletHome)