import OrderFlowLocale from "../OrderFlowLocale";

export default {
    da: {
        ...OrderFlowLocale.da,
        showtimeUnNumbered: 'Venligst bemærk at pladserne i salen er unummereret. <br /> Først til mølle princippet gælder.',
        oneSeat: 'Valgt plads: ',
        multiSeats: 'Valgte pladser: ',
        row: 'Række',
        seat: 'Sæde',
        seats: 'Sæder',
        seatsUnNumbered: 'Unummereret',
        seatBlue: 'Dit valg',
        seatGreen: 'Ledige',
        seatWhite: 'Reserveret',
        seatRed: 'Solgt',
        chooseSeats: 'Du skal vælge mindst en billet for at vælge sæder',
        chooseTickets: 'Du skal vælge mindst en billet for at fortsætte',
        discountTextBioKlubDk: 'Biografklub Danmark kan ikke angives her. Disse indtastes senere i forløbet.',
        discountTextGiftCard: 'Filmporten og Gavekort kan ikke angives her. Disse indtastes senere i forløbet.',
        discountTextBoth: 'Biografklub Danmark og gavekort kan ikke angives her. Disse indtastes senere i forløbet.',

        noOnlineOrder: 'Denne forestilling kan ikke bestilles online på nuværende tidspunkt',
        noReserveClubCard: 'Du kan ikke reservere når du er logget på klubkort',
        noReserve: 'De valgte billetter kan ikke reserveres',
        noBuy: 'De valgte billetter kan ikke købes',

        backProgram: '❮  Program',
        backAll: '❮  Alle Film',
        backFuture: '❮  Kommende Film',
        backMovie: '❮  Andre Tider',
        buy: 'Køb   ❯',
        back:'❮   Tilbage',
        nextBuyBioKlubDk: '   Køb med Biografklub Danmark  ❯   ',

        extraPurchasesReset: 'Du har flyttet dine sæder til eller fra pladser med specialtilkøb tilgængeligt, så dine valgte tilkøb er blevet nulstillet.'
    },

    en: {
        ...OrderFlowLocale.en,
        showtimeUnNumbered: 'Please note that the seats in this hall are unnumbered. <br /> First come, first serve.',
        oneSeat: 'Chosen seat: ',
        multiSeats: 'Chosen seats: ',
        row: 'Row',
        seat: 'Seat',
        seats: 'Seats',
        seatsUnNumbered: 'Unnumbered',
        seatBlue: 'Your seats',
        seatGreen: 'Available',
        seatWhite: 'Reserved',
        seatRed: 'Sold',
        chooseSeats: 'You have to select minimum one ticket to choose seats',
        chooseTickets: 'You have to select minimum one ticket to continue',
        discountTextBioKlubDk: '"Biografklub Danmark" can not be used here. They can be added later in the process.',
        discountTextGiftCard: 'Gift cards can not be used here. They can be added later in the process.',
        discountTextBoth: '"Biografklub Danmark" and gift cards can not be used here. They can be added later in the process.',

        noOnlineOrder: 'This showtime can\'t be ordered online',
        noReserveClubCard: 'You can\'t reserve tickets when you are logged into as a club card owner',
        noReserve: 'The chosen tickets can\'t be reserved',
        noBuy: 'The chosen tickets can\'t be bought',

        backProgram: '❮  Program',
        backAll: '❮  All Movies',
        backFuture: '❮  Future Movies',
        backMovie: '❮  Other Showtimes',
        buy: 'Buy  ❯',
        back: '❮  Back',
        nextBuyBioKlubDk: 'Buy with "Biografklub Danmark"',

        extraPurchasesReset: 'You have moved your seats to or from places where there are special extra purchases available. Your previously chosen extra purchases have been reset.'
    },

    fo: {
        ...OrderFlowLocale.fo,
        showtimeUnNumbered: 'Vinarliga leggi tilmerkis at stólar í salinum eru ótalmerktir. <br /> Tey ið koma fyrst, fáa fyrst pláss.',
        oneSeat: 'Valt pláss: ',
        multiSeats: 'Valt pláss: ',
        row: 'Rekkja',
        seat: 'Stólur',
        seats: 'Stólar',
        seatsUnNumbered: 'Ótalmerkt',
        seatBlue: 'Títt val',
        seatGreen: 'Tøk',
        seatWhite: 'Umbiðin',
        seatRed: 'Upptiki',
        chooseSeats: 'Tú skal velja minst eitt atgongumerki fyri at velja stól',
        chooseTickets: 'Tú skal velja minst eitt atgongumerki fyri at halda áfram',
        discountTextBioKlubDk: '"Biografklub Danmark" kann ikki skrivast her. Hesi innloysast seinni í tilgongdini.',
        discountTextGiftCard: 'Gávukort kunnu ikki skrivast her. Hesi innloysast seinni í tilgongdini.',
        discountTextBoth: '"Biografklub Danmark" og gávukort kunnu ikki skrivast her. Hesi innloysast seinni í tilgongdini.',

        noOnlineOrder: 'Henda sýningin kann ikki bíleggjast á netinum í løtuni',
        noReserveClubCard: 'Tú kann ikki umbiða tá tú er innritaður við klubkort',
        noReserve: 'Valdu atgongumerkini kunna ikki avleggjast',
        noBuy: 'Valdu atgongumerkini kunna ikki keypast',
        buy:'Keyp ❯',
        backProgram: '❮  Skrá',
        backAll: '❮  Allir filmar',
        backFuture: '❮  Komandi filmar',
        backMovie: '❮  Aðrar tíðir',

        nextBuyBioKlubDk: 'Keyp við "Biografklub Danmark"',

        extraPurchasesReset: 'Tú hevur flutt tíni atgongumerki til ella fra plássi við sertilkeyp. Tíni valdu tilkeyp eru strika.'
    }

    //new: {
    //    ...OrderFlowLocale.new,
    //    showtimeUnNumbered: '',
    //    oneSeat: ': ',
    //    multiSeats: ': ',
    //    row: '',
    //    seat: '',
    //    seats: '',
    //    seatsUnNumbered: '',
    //    seatBlue: '',
    //    seatGreen: '',
    //    seatWhite: '',
    //    seatRed: '',
    //    chooseSeats: '',
    //    chooseTickets: '',
    //    discountTextBioKlubDk: '"Biografklub Danmark" ',
    //    discountTextGiftCard: '',
    //    discountTextBoth: '"Biografklub Danmark" ',

    //    noOnlineOrder: '',
    //    noReserveClubCard: '',
    //    noReserve: '',
    //    noBuy: '',

    //    backProgram: '❮  ',
    //    backAll: '❮  ',
    //    backFuture: '❮  ',
    //    backMovie: '❮  ',

    //    nextBuyBioKlubDk: ' "Biografklub Danmark"',

    //    extraPurchasesReset: ''
    //}
}