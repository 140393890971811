import React, { useEffect, useState } from 'react'
import '../styles/program.scss'
//import CONSTANTS from '../movielist-constants'
//import xml2js from '../../lib/xml-to-js';
//import DayPicker from 'react-day-picker';
//import moment from 'moment'
//import downarrow from './images/down-arrow-backup-2-svgrepo-com (1).svg'
//import check from "./images/check-mark.svg";
import checkIcon from "../images/check-mark.svg";



export default function Cinema({ allOrganizers, setCinemaID, resetCinema, setResetCinema, regarr, cinemaarr, setCinemaarr, cinemaIds, setCinemaIds }) {

   
    //console.log(allOrganizers, 'all orgs')
    // choose biograf functions
    const [isCinemaOpen, setIsCinemaOpen] = useState(false);
    const [city, setCity] = useState('')
    const [toggle, setToggle] = useState(true)
   // const [chosenCinema, setChosenCinema] = useState('Vælge biograf');
    const [chosenCinemaCity, setChosenCinemaCity] = useState('')
    // const [Dd, setDd] = useState({data.map(item.id)});
   
    function handleArrrow() {
        setIsCinemaOpen((current) => !current);
    }

  

    useEffect(() => {
        resetCinema == true && setChosenCinema('Vælge biograf')
        resetCinema == true && setChosenCinemaCity(' ')


    }, [resetCinema])

    function handleCheck(item) {
        //console.log(item, 'item cinema')
     //   console.log(cinemaarr)
        setCity(item.city)
        if (cinemaarr.includes(item.name)) {
            setCinemaarr(cinemaarr.filter(r => { return r !== item.name }))
            setCinemaIds(cinemaIds.filter(r => { return r !== item._no }))
        }
        else setCinemaarr([...cinemaarr, item.name]), setCinemaIds([...cinemaIds, item._no])
      //  console.log(e, 'cinema e')
        //setCinemaID(e._no)
        //allOrganizers.map(i => {
        //    if (i.id === e.id) {
        //        let p = item.target
        //        let img = document.createElement('img')
        //        img.src = toggle ? checkIcon : ''
        //        p.append(img)
        //        // console.log('click')
        //        item.target.parentElement.style.color = toggle ? '#fff' : '#999'
        //        setToggle(!toggle)
        //        //setIsCinemaOpen(false)


        //    }
        //    setChosenCinema(e.name);
        //    setChosenCinemaCity('/ '+e.city);
        //})

        setResetCinema(false)
    }
    //console.log(cinemaarr)
    //console.log(cinemaIds)
    return (
       
           

        <div >
                    <p className="region-title">Vælg biograf</p>
                    <div className='chooseCinema'>
                        <div
                            style={{
                                position: "relative",
                                padding: "5px 8px",
                                boxSizing: "border-box",
                                height: "31px",
                    }}  >
                    <p style={{ margin: 0 }}> {cinemaarr && cinemaarr?.length === 1 ? cinemaarr[0] : cinemaarr?.length > 0 ? 'Valgte biografer' : 'Vælge biograf'} {cinemaarr?.length === 1 && '/ ' + city}</p>
                    <span className="one"> {cinemaarr?.length > 0 && cinemaarr?.length} </span>
                            <span
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    height: "31px",
                                    right: "35px",
                                    borderLeft: "0.1px solid grey",
                                }}
                            ></span>
                            <button
                                onClick={handleArrrow}
                                style={{
                                    position: "absolute",
                                    bottom: "8px",
                                    right: "-3px",
                                    border: "none",
                                    outline: "none",
                                    background: "transparent",
                                }}
                            >
                                <p className={isCinemaOpen ? "rotated" : ""}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                <path d="M1 1L7.48151 8L13.963 1" stroke="#757575" />
                            </svg>
                                </p>
                            </button>
                        </div>
                    </div>
                    {isCinemaOpen &&
                        <div className="scroll" style={{
                            borderLeft: "1px solid #757575",
                            borderRight: "1px solid #757575",
                            borderBottom: "1px solid #757575",
                            color: '#999',
                            backgroundColor: "#3a3a3a",
                            maxHeight: "255px",
                            overflowX: 'auto',
                            position: 'relative',
                            zIndex: 100
            }}>
                {allOrganizers && allOrganizers.map((item) => (

                    <div key={item._no } onClick={() => handleCheck(item)}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        height: "31px",
                                        padding: "8px",
                                        marginRight: '2px',
                                        borderRight: '1px solid #757575'

                                    }}
                                >
                        <p style={{ margin: 0, color: cinemaarr.length && cinemaarr.includes(item.name) ?  '#fff' : '#999' }}>{item.name} / {item.city}</p>
                                    <p
                                        className="checkCheckbox"
                                        style={{ position: "relative", top: "-14px" }}

                                    >
                            {cinemaarr.length && cinemaarr.includes(item.name)?
                                            <img
                                                style={{ position: "absolute", top: "2px", left: "3px" }}
                                                src={checkIcon} alt=""
                                            />
                                            : ''}
                                    </p>
                                </div>
                            ))}

                        </div>
                    }

                </div>

        
        
        )
}