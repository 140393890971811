import React, { useEffect, useState,} from 'react'
import '../styles/program.scss'
import _, { forEach, groupBy, includes } from 'lodash'
import DatePickerContainer from '../../datepicker/DatePickerContainer'
//import CONSTANTS from '../movielist-constants'
import xml2js from '../../../lib/xml-to-js';
import DayPicker from 'react-day-picker';
import moment from 'moment'
//import sortIcon from './images/sort-icon.png'
//import check from "./images/check-mark.svg";
//import { ROUTES } from '../navigation/route-constants'
//import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import downarrow from '.././images/down-arrow-backup-2-svgrepo-com (1).svg'
//import check from "./images/check-mark.svg";
//import checkIcon from "./images/check-mark.svg";
import Region from './Region';
import Cinema from './Cinema';
//import Sorting from './Sorting';
import LOCALES from '../../overlays/OverlayLocales'


import { useLocation } from "react-router-dom";
import LOCALE from '../../datepicker/DatePickerLocale'




export default function Program({ allMovies, allEvents, allOrganizers, locations, ageCensor }) {
    const location = useLocation();
    
    const history = useHistory();
    let sorting = [
        {
            id: 0,
            name: 'Alfabetisk sorteret'
        },
        {
            id: 1,
            name: 'Mest solgte'
        },
        {
            id: 2,
            name: 'Mest programlagte'
        },
        {
            id: 3,
            name: 'Nyeste'
        },
    ]
  
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedMovieId, setSelectedMovieId] = useState('')
    const [chosenRegion, setChosenRegion] = useState([]);
    const [chosenCinema, setChosenCinema] = useState([]);
    const [noFilmError, setNoFilmError] = useState([]);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [ filteredMovies, setFilteredMovies] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment().format('DD-MM-YYYY'))
    const [selected, setSelected] = useState(1);

    const [filteredEvents, setFilteredEvents] = useState([])
 
    const [isOpen, setIsOpen] = useState(false);
    const [sortedBy, setSortedBy] = useState('Alfabetisk sorteret');
    const [sortID, setSortID] = useState(0)
    const [cinemaID, setCinemaID] = useState(0)
    const [orgsPerRegion, setOrgPerRegion] = useState([])
    const [resetCinema, setResetCinema] = useState(false)
    const [regarr, setRegarr] = useState([])
    const [cinemaarr, setCinemaarr] = useState([])
    const [cinemaIds, setCinemaIds] = useState([])
    const [sortedEventList, setSortedEventList] = useState([{}])
    const [movietoday, setMovietoday] = useState([{}])
    const [sortedEvents, setSortedEvents] = useState([])
    let evs = []
    let ev = []

    //console.log(selected, 'selected from ebillet program')
    //  console.log(allEvents, 'all events')  
    // ----------SELECTED DATE CALCULATIONS---------------------
    const daysPerWeek = [
        { id: 1, title: "I dag", nr: "0" },
        { id: 2, title: "I morgen", nr: "0" },
        { id: 3, title: moment().add(2, 'days').locale("da").format('ddd. DD/MM') , nr: "0" },
        { id: 4, title: moment().add(3, 'days').locale("da").format('ddd. DD/MM'), nr: "0" },
        { id: 5, title: moment().add(4, 'days').locale("da").format('ddd. DD/MM'), nr: "0" },
    ]
   
    function handleDayClick(days) {
        setSelected(days.id)
       
        if (days.id === 2) { setSelectedDate(moment().add(1, 'days').format('DD-MM-YYYY')) }
         else  if (days.id === 3) { setSelectedDate(moment().add(2, 'days').format('DD-MM-YYYY')) }
         else  if (days.id === 4) { setSelectedDate(moment().add(3, 'days').format('DD-MM-YYYY')) }
         else  if (days.id === 5) { setSelectedDate(moment().add(4, 'days').format('DD-MM-YYYY')) }
         else setSelectedDate(moment().format('DD-MM-YYYY'))
    }
   
    // ----------Calculating MOST PROGRAMMED MOVIE---------------
    let moviesWithEvents = []
   
    function findOcc(arr, key) {
     
        let arr2 = [];

        arr.forEach((x) => {

            // Checking if there is any object in arr2 
            // which contains the key value 
            if (arr2.some((val) => { return val[key] == x[key] })) {

                // If yes! then increase the occurrence by 1 
                arr2.forEach((k) => {
                    if (k[key] === x[key]) {
                        k["occurrence"]++
                    }
                })

            } else {
                // If not! Then create a new object initialize  
                // it with the present iteration key's value and  
                // set the occurrence to 1 
                let a = {}
                a[key] = x[key]
                a["occurrence"] = 1
                arr2.push(a);
            }
        })

        return arr2, moviesWithEvents.push(arr2)

    }
   
    

    /**----------CATCHING ERROR HERE IF CERTAIN BIOGRAF DOES NOT HAVE MOVIE ON SELECTED DATE---------- */
    useEffect(() => {
        if (cinemaIds.length && !ev.length) {
            setNoFilmError('ingen film hos valgte biograf i valget dato')
        }
        else setNoFilmError('')
        }, [cinemaIds, ev])
 

    useEffect(() => {
        if (regarr.length > 0 && !evs.length) {
            setNoFilmError('ingen film i valgte region')
        }
    }, [evs, regarr])

   
    /**------Gettting the filtered regions here ------------- */
    let filteredRegion = []  // contains filtered regions from choose region field
    regarr.forEach(checkReg);
    
    function checkReg(item) {
        allOrganizers.filter(org => {
            if (org.region == item) filteredRegion.push(org)
        })
    }
   // console.log(filteredRegion, 'filter region')
    allEvents.map(e => {
        filteredRegion.map(reg => {

            if ((reg._no === e._organizerNo) && (moment(e.dateTime).format('DD-MM-YYYY') === selectedDate)) {
                evs.push(e)       // evs contains events with filtered region and selected date      
            }
        })
    })
    let eventsWithReg = []
    allEvents.map(e => {
        filteredRegion.filter(reg => {
            if (reg._no === e._organizerNo) {
                eventsWithReg.push(e)
            }
        })
    })
  
  //  console.log(eventsWithReg, 'eventsWithReg')
    useEffect(() => {
        eventsWithReg && setFilteredEvents(eventsWithReg)  // adding filtered events with region
    }, [regarr])

    useEffect(() => {
        evs && setSortedEvents(evs)
    }, [selectedDate, regarr])
  //  console.log(regarr, 'reg array')


    /**-----GETTING FILTERED BIOGRAFS HERE------- */
    let filteredCinemas = []
    cinemaIds.forEach(checkCinemaIds)

    function checkCinemaIds(item) {
        allOrganizers.filter(org => {
            if (org._no === item) filteredCinemas.push(org)
        })
    }
  
        allEvents.map(event => {
            filteredCinemas.map(cinema => {
                if (event._organizerNo === cinema._no && moment(event.dateTime).format('DD-MM-YYYY') === selectedDate ) {
                
                    ev.push(event)
                }
            })
        }) 
        // map function below returns events with chosen cinema
    let eventsWithCinema = []
    allEvents.map(event => {
        filteredCinemas.map(cinema => {
            if (event._organizerNo === cinema._no) {

                eventsWithCinema.push(event)
            }
        })
    })

    useEffect(() => {
        eventsWithCinema && setFilteredEvents(eventsWithCinema)
    }, [cinemaIds])

   // console.log(eventsWithCinema, 'eventswithcinema')
 
    useEffect(() => {
        ev.length && setSortedEvents(ev)
    }, [cinemaIds, selectedDate])
 

    /**---- FILTERING FILM  WITH SELECTED DATE --- */
    let showsPerDay = [{}]
   

   
    let eventsWithFilter = (cinemaIds.length || regarr.length) && sortedEvents.length ? sortedEvents: allEvents

   
    let eventsPerDay = [{}]
    eventsWithFilter.map((e) => {
        // console.log(moment(e.dateTime).format('DD-MM-YYYY'))
        if (moment(e.dateTime).format('DD-MM-YYYY') === selectedDate) {
          
              allMovies.map((m) => {
               
                    if (m._no === e._movieNo  ) {
                      
                        showsPerDay.push(m)
                        eventsPerDay.push(e)
                    }           
               
            })
        }
    })
  //  console.log(eventsWithFilter, 'events for filter')
    findOcc(eventsPerDay, '_movieNo')  // findOcc function counts how many times a movie occurs in allEvents

    //find duplicates
    function toFindDuplicates(showsPerDay) {
        const uniqueElements = new Set(showsPerDay);

        showsPerDay.filter((item) => {
            if (uniqueElements.has(item)) {

            } else {
                return item;
            }
        });

        return [...new Set(uniqueElements)];
    }

  
    const showsToday = toFindDuplicates(showsPerDay) 

    allMovies.map((movie) => {
        if (movie._movieBaseNo > 0) {
            //console.log(movie)
        }
    })

    
        
    // ASSIGNING MOST PROGRAMMED IN ALLMOVIES
    allMovies.map((movie) => {
        moviesWithEvents[0].map((e) => {
            if (e._movieNo === movie._no) {
                Object.assign(movie, { most_programmed: e.occurrence })

            }
        })
    })

    // ----------ASSIGNING USEDCOUNT  INTO ALLEVENTS  WHICH IS TOTALCOUNT - FREECOUNT------------
   
    useEffect(() => { 
    allEvents.map((event) => {
         locations.map((loc) => {
          

            if (event._locationNo === loc._no && loc._organizerNo === event._organizerNo) {
                let c = loc.seats._totalCount
                let v = event.seats._freeCount
               
                let usedCount = c - v

                Object.assign(event, { usedCount: usedCount })


            }

        })
    })
    }, [])

   // console.log(cinemaID, 'org nummer')

    // ----------SUMMING USEDCOUNT IN ALLEVENTS---------------
    useEffect(() => {
        let result = cinemaID ? (allEvents.filter((e) => { return e._organizerNo === cinemaID })) : allEvents.reduce((a, v) => {
            let obj = a.find(i => i._movieNo === v._movieNo)
            if (obj) {
               
                obj.usedCount += v.usedCount;
            } else {
                a.push(v);
            }
            return a;

        }, [])


        //------------ASSIGNING THE TOTAL OF USED COUNT IN ALLMOVIES -- calculate usedcount from event and sum those as per movie---------
        allMovies.map((movie) => {
            result.map((res) => {
                if (res._movieNo === movie._no) {
                    Object.assign(movie, { most_sold: res.usedCount })
                }

            })
        })

    })


    function handleArrrow() {
        setIsOpen((current) => !current);
    }

    function handleSort(item) {
        //console.log('handle clicked')
        setSortID(item.id)
        setSortedBy(item.name)
        setIsOpen(false)
    }






    // ----------FILTERING MOVIES WITH CHOOSE REGION----------------- 
  //  console.log(orgsPerRegion, 'orgs per region')
    let orgsArr = []



    useEffect(() => {
        orgsPerRegion && orgsPerRegion.map((org) => {
            // console.log(org._no)
            orgsArr.push(org._no)
        })
    })



    let arr = [];

    allOrganizers.filter((i) => arr.push(i.region))

   


    function handleRegionArrrow() {
        setIsOpen((current) => !current);
    }

    function toggleCalendar() {
        setShowCalendar(true)
    }
    function closeCalendar() {
        setShowCalendar(false)
    }
    /*** folowing function runs when clicked on FLERE DATOER */

    function handleDateClick(e) {
    
        setCalendarDate(new Date(e))
       // console.log(moment(e).format('DD-MM-YYYY'))
        setSelectedDate(moment(e).format('DD-MM-YYYY'))
        closeCalendar();
        setSelected(0)
    }
    function movieId(id) {
    
        let mo = []
        showsToday.map(m => {
            if (m._no == id) {
                mo.push( m )
               
            }
        })
        return mo
    }

 //  console.log(selectedMovieId, 'select movie')

    let eventsWithMovieArr=[]
    allEvents.map(ev => {
        let e
       
        if (ev._movieNo == selectedMovieId ) {
                
            eventsWithMovieArr.push(ev)
          
        }
        
    })

   
    //useEffect(() => {

    //    orgsWithChosedMovie.map(org => {
    //        locations.map(loc => {
    //            if (loc._organizerNo === org._organizerNo) {
    //                //console.log(loc.city)
    //            }
    //        })

    //    })
    //}
    //)

    //const [selectedMovie, setSelectedMovie] = useState()
    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    showsToday.map((movie) => {
        // if (movie.censoring) {
        let n = movie.censoring
        const mc = isEmpty(movie.censoring) === false ? _.find(ageCensor, (icon) => { return n.includes(icon.id) }) : null
        
        Object.assign(movie, { censorImage: isEmpty(movie.censoring) === false ? mc?.image : null })
        // }
    })

    let eventsForFilter = (cinemaIds.length || regarr.length) && filteredEvents.length ? filteredEvents : allEvents
   // console.log(eventsForFilter, 'events for filter')

    function handleMovieClick(e) {      
        
        setSelectedMovieId(e.target.id)   
        let movie_id = e.target.id  
       
        let clickedMovie = movieId(movie_id)
       
        const movieData = isBaseMovie(clickedMovie)
       
        movie_id && history.push('/film-details', { movieData,  movie_id, allOrganizers, clickedMovie, selectedDate, selected, eventsForFilter })
    }

    function isBaseMovie(clickedMovie) {
      
        let movie_arr = []
       
        allMovies.map((movie) => {
            if (clickedMovie[0]._movieBaseNo > 0) {


                if (movie._movieBaseNo === clickedMovie[0]._movieBaseNo) {
                    movie_arr.push(movie)
                }
            }
            else if (movie._no === clickedMovie[0]._no) {
                movie_arr.push(clickedMovie[0])

            }
             })
        
        
        return movie_arr
    }


   // console.log(showsToday)

    return (
        <>
           
             {allMovies.length > 1 ? (

                 <main className='AllMovies'>
           <section className='header-section' >
            <div className='chooseFromContainer'>
                       
                  <div>
                    <p className='chooseDay'>Vælg dag</p>
                    <div style={{marginTop:'-15px'} }>
                       {daysPerWeek.map((days, index) => (
                           <button key={days.id} className={`days-btns day_btns_${index}`}
                                style={{ backgroundColor: days.id === selected ? '#fff' : '', color: days.id=== selected ? '#323232':'' }}
                               onClick={() => handleDayClick(days)}> {days.title}   </button>))}

                   <button className='days-btns' style={{ width: '106px', }} onClick={toggleCalendar}> Flere datoer</button>
                    </div>
               </div>

                        
              <Region allOrganizers={allOrganizers} setOrgPerRegion={setOrgPerRegion}
                 setResetCinema={setResetCinema}  regarr={regarr} setRegarr={setRegarr} />

               <Cinema
                    allOrganizers={filteredRegion.length > 0 ? filteredRegion : allOrganizers} cinemaIds={cinemaIds} setCinemaIds={setCinemaIds}
                                setCinemaID={setCinemaID} resetCinema={resetCinema} setResetCinema={setResetCinema} regarr={regarr} cinemaarr={cinemaarr}
                                setCinemaarr={setCinemaarr} />
                           
              </div>
            </section>
                    {showCalendar && 
                  <div className={showCalendar ? 'Calendar slide-down' : 'Calendar slide-up'}>
                        <span className="icon-close" onClick={closeCalendar}></span>
                        <DayPicker
                           mode='single'
                          // initialMonth={firstDate}
                          // fromMonth={firstDate}
                          // toMonth={lastDate}
                            selectedDays={calendarDate}
                            selected={calendarDate}
                            onSelect={setCalendarDate}
                           
                            months={LOCALE.da.months} //This is only the display name of the from-to months, unfortunately not which months to include in the calendar. This can't seem to be done with this lib.
                           // weekdaysLong={LOCALE[appLocale].weekdays}
                            weekdaysShort={LOCALE.da.weekdaysShort}
                            firstDayOfWeek={1}
                            enableOutsideDays={false}
                           // selectedDays={selectedDate}
                            disabledDays={{ before: new Date() }}
                            onDayClick={handleDateClick}
                            fixedWeeks
                        />
                       {/*{calendarText ? <p className="calendar-text" dangerouslySetInnerHTML={{ __html: calendarText }}></p> : null}*/}
                        </div>
                   }

                   {/* <AllRegions allOrganizers={allOrganizers} />*/}

                    <section className='programFilmContainer'>

                       <div className='program-header'>
                            <p className='program'>Program</p>

                            <div className='sorting' >
                                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.08 5.50024V16.64" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="1" x2="16.52" y2="1" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="6.40002" x2="11.12" y2="6.40002" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <line x1="1" y1="11.8" x2="7.04" y2="11.8" stroke="white" stroke-width="2" stroke-linecap="round" />
                                    <path d="M11.16 11.96L16.08 16.88L21 11.96" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <div className=''>
                                    <div className='sortingContainer' >
                                        <div style={{
                                            padding: "5px 8px",
                                            boxSizing: "border-box",
                                            height: "31px",
                                        }}>
                                           <p style={{ margin: 0, }}>{sortedBy}</p>
                                            <span className='line'></span>
                                            <button
                                                onClick={() => handleArrrow()}
                                                style={{
                                                    position: "absolute",
                                                    bottom: "7px",
                                                    right: "-3px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "transparent",
                                                }}
                                            >
                                                <p className={isOpen ? "rotated" : ""}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                        <path d="M1 1L7.48151 8L13.963 1" stroke="#757575" />
                                                    </svg>
                                                </p>
                                            </button>
                                        </div>

                                        {isOpen &&
                                            <div className="sortingDiv">
                                                {sorting && sorting.map((item) => (
                                                    <p key={item.id} id={item.id} style={{ height: '15px' }} /*onChange={() => setSortID(item.id)}*/ onClick={() => handleSort(item)}>{item.name}</p>
                                                ))}

                                            </div>

                                        }
                                    </div>

                               </div>
                           </div>
                        </div>

                        {noFilmError ?  <p>{noFilmError}</p> : 
                       <div className='all-film-program' >

              {showsToday.length > 1 ? 
                       showsToday.sort((a, b) => (
                             sortID == 1 ? a.most_sold > b.most_sold ? -1 : 1 :
                             sortID == 2 ? a.most_programmed > b.most_programmed ? -1 : 1 :
                                                    sortID == 3 ? moment(a.openingDate).format("YYYY-MM-DD") > moment(b.openingDate).format("YYYY-MM-DD") ? -1 : 1 
                                                    
                                                    : a.name > b.name ? 1 : -1))
                   
                                        .map((item, index) => (
   
                                            item.name &&
                                            <div className='film-program-item'  key={index}  >
                                                    <div id={item._no} className='poster' style={{ position: 'relative', }}>
                                                        <img id={item._no} onClick={(item) => handleMovieClick(item)} style={{ width: '100%' }} src={'https://poster.ebillet.dk/' + `${item.largePath}`} alt="movie-banner" />
                                                        <div className='age-banner'> {isEmpty(item.censoring) === false ?
                                                            <img style={{ position: 'absolute',bottom:'10px',right:'7px' }} src={item.censorImage} alt='age-banner' /> : null}
                                                        </div>
                                                    </div>
                                         
                                                    <div className='film-btn-container' >
                                                        <p style={{ fontSize: '22px', margin: '0', height: '60px' }} className='ellipsis-twoline'>{item.name}</p>
                                                        <button className='film-program-btn' id={item._no} onClick={(item) => handleMovieClick(item)} 
                                                        > Billetter</button>
                                   </div>
                               </div>
                                
                           )) : (<h1>loading..........</h1>)
                           }
                           </div>
                       }
                    </section>

                </main>
            )
                :
                <div className={`Spinner overlay ${allMovies ? ' fade-in' : ' fade-out'}`}>
                    <p className="overlay-content">Indlæser..........</p>
                </div>
            }


        </>
    )
}